import { i18n } from "shared/utilities/I18nContext";
import { microValueToDollarValue } from "shared/utilities/numbers";

export default function formatNumber(v: number, wholeNumber?: boolean): string {
  let unit = "";
  let unitAdjustedNumber = v;

  if (v / 10 ** 6 >= 1) {
    unitAdjustedNumber = v / 10 ** 6;
    unit = "mil";
  } else if (v / 10 ** 3 >= 1 && v / 10 ** 3 < 10) {
    const roundedValue = Math.floor(v);
    return i18n.formatNumber(roundedValue);
  } else if (v / 10 ** 3 >= 10) {
    unitAdjustedNumber = v / 10 ** 3;
    unit = "k";
  } else if (v < 1 && v > 0) {
    return wholeNumber ? `${Math.round(unitAdjustedNumber * 100)}%` : `${(unitAdjustedNumber * 100).toFixed(2)}%`;
  } else if (v / 10 ** 3 < 10 && v / 10 ** 3 > 0) {
    return wholeNumber ? v.toString() : v.toPrecision(4);
  }

  return wholeNumber
    ? `${unitAdjustedNumber}${unit}`
    : `${unitAdjustedNumber.toPrecision(precisionForWholeNumber(unitAdjustedNumber, 1))}${unit}`;
}

export function formatPercent(v: number, wholeNumber?: boolean): string {
  const absoluteNumber = Math.abs(v);
  return wholeNumber ? `${Math.round(absoluteNumber * 100)}%` : `${(absoluteNumber * 100).toFixed(2)}%`;
}

function precisionForWholeNumber(v: number, minimumPrecision: number): number {
  const exponents = Math.floor(Math.log10(v)) + 2;
  if (exponents > minimumPrecision) {
    return exponents;
  }

  return minimumPrecision;
}

export function formatMicroDollars(v: number): string {
  return formatDollars(microValueToDollarValue(v));
}

export function formatDollars(v: number): string {
  return `$${formatNumber(v)}`;
}
