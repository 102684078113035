import LogoutIcon from "@mui/icons-material/Logout";
import { Icon, IconButton, InputAdornment } from "@mui/material";
import classNames from "classnames";
import { useContext, useState } from "react";
import { DateRange } from "shared/components/DateRangeSelect";
import { ISelectOption } from "shared/components/ISelectOption";
import { MaterialAutocompleteField } from "shared/components/MaterialAutocomplete";
import MaterialDateRangeSelect from "shared/components/MaterialDateRangeSelect";
import TextField, { TextFieldProps } from "shared/components/TextField";
import { I18nContext } from "shared/utilities/I18nContext";
import RemergeLogoMobile from "../assets/remerge-logo-mobile.svg";
import RemergeLogo from "../assets/remerge-logo.svg";
import { formatDateRangeForAPICall } from "../common/formatDate";
import { PickDateRangeEvent, useDebouncedEventTracking } from "../common/tracking";
import classes from "./Header.module.css";

type ClientOption = {
  value: string;
  label: string;
  logo: string;
};

type HeaderProps = {
  multipleOrgs: boolean;
  clientOptions: ClientOption[];
  selectedClient: string;
  setSelectedClient: (val: string) => void;
  dateRange: DateRange;
  previousDateRange: DateRange;
  setDateRange(dateRange: DateRange): void;
  logOut(): void;
};

type ClientSelectionProps = {
  clientOptions: ISelectOption[];
  selectedClient: string;
  setSelectedClient: (val: string) => void;
  setClientSelectionOpen: (val: boolean) => void;
  clientSelectionOpen: boolean;
};

function ClientSelection({
  clientSelectionOpen,
  selectedClient,
  clientOptions,
  setClientSelectionOpen,
  setSelectedClient,
}: ClientSelectionProps) {
  return (
    <div className={classNames(classes.clientSelection, clientSelectionOpen ? classes.visible : classes.hidden)}>
      <MaterialAutocompleteField
        name="client-selection"
        value={selectedClient}
        id="client-selection"
        errors={[]}
        label=""
        fullWidth
        options={clientOptions}
        onChange={val => {
          setSelectedClient(val);
          setClientSelectionOpen(false);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon>search</Icon>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

export default function Header({
  multipleOrgs,
  clientOptions,
  selectedClient,
  setSelectedClient,
  dateRange,
  previousDateRange,
  setDateRange,
  logOut,
}: HeaderProps) {
  const [clientSelectionOpen, setClientSelectionOpen] = useState(false);
  const isMobile = document.body.clientWidth < 800;
  const mobileMinWidth = "180px";
  const desktopMinWidth = "220px";
  const translations = useContext(I18nContext).translations;

  const trackEvent = useDebouncedEventTracking<PickDateRangeEvent>(10000);

  const handleDateRangeChange = (dateRange: DateRange) => {
    setDateRange(dateRange);
    const { startDate, endDate } = formatDateRangeForAPICall(dateRange);
    if (!startDate || !endDate) return;

    trackEvent(
      new PickDateRangeEvent({
        "Date Range Start": startDate,
        "Date Range End": endDate,
        "Organization Id": selectedClient,
      }),
    );
  };

  const clientLogo = clientOptions.find(option => option.value === selectedClient)?.logo;

  return (
    <>
      <div className={classes.header}>
        <div className={classes.topHeader}>
          <div className={classes.logoContainer}>
            <img className={classNames(classes.logo, classes.bigLogo)} src={RemergeLogo} alt="Remerge logo" />
            <img className={classNames(classes.logo, classes.smallLogo)} src={RemergeLogoMobile} alt="Remerge logo" />
          </div>

          <div
            onClick={() => setClientSelectionOpen(!clientSelectionOpen)}
            className={classNames(classes.clientLogo, multipleOrgs && classes.clickableLogo)}
          >
            <img src={clientLogo} alt="client logo" className={classes.clientLogo} style={{ maxHeight: "50px" }} />
            {multipleOrgs && (
              <Icon fontSize="large">{clientSelectionOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
            )}
          </div>

          <div className={classes.logOut}>
            <div className={classes.logOutButton}>
              <IconButton title={translations.common.logout} color="inherit" onClick={logOut} size="large">
                <LogoutIcon />
              </IconButton>
            </div>
          </div>
        </div>

        <div className={classes.subHeader}>
          <div className={classes.datePicker}>
            <MaterialDateRangeSelect
              value={dateRange}
              onChange={handleDateRangeChange}
              renderTriggerButton={(dateRange, onClick) => (
                <DateRangeField
                  dateRange={dateRange}
                  onClick={onClick}
                  label="Selected Period"
                  variant="standard"
                  style={{ minWidth: isMobile ? mobileMinWidth : desktopMinWidth }}
                />
              )}
            />
          </div>

          <div className={classes.datePicker}>
            <MaterialDateRangeSelect
              value={previousDateRange}
              onChange={() => {}}
              renderTriggerButton={(dateRange, onClick) => (
                <DateRangeField
                  dateRange={dateRange}
                  onClick={onClick}
                  label="Previous Period"
                  variant="standard"
                  disabled
                  style={{ minWidth: isMobile ? mobileMinWidth : desktopMinWidth }}
                />
              )}
            />
          </div>
        </div>
      </div>

      {multipleOrgs && (
        <ClientSelection
          selectedClient={selectedClient}
          clientOptions={clientOptions}
          setSelectedClient={setSelectedClient}
          setClientSelectionOpen={setClientSelectionOpen}
          clientSelectionOpen={clientSelectionOpen}
        />
      )}
    </>
  );
}

type DateRangeFieldProps = Omit<TextFieldProps, "onChange" | "value"> & {
  dateRange: DateRange;
};

function DateRangeField({ dateRange, ...rest }: DateRangeFieldProps) {
  const i18n = useContext(I18nContext);
  const formattedDateRange = i18n.formatDateRange(dateRange, { format: "dateUtc", divider: "to" });

  return <TextField value={formattedDateRange} {...rest} />;
}
