import { FormControl, FormControlProps, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { FieldProps } from "shared/components/form/FieldProps";
import { ISelectOption, optionKey } from "shared/components/ISelectOption";

type ValueType = ISelectOption["value"] | ISelectOption["value"][];

export interface MaterialSelectFieldProps extends FieldProps<ValueType, FormControlProps> {
  options: ISelectOption[];
  warning?: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 5,
  },
}));

function MaterialSelectField(props: MaterialSelectFieldProps): JSX.Element {
  const renderValue = (selectedValue: any) => {
    const { options } = props;
    const selectedOption = options.find(option => option.value === selectedValue);
    return selectedOption && selectedOption.label;
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  };

  const { onChange: unused, id, label, errors, options, value, name, warning, ...restProps } = props;

  const classes = useStyles();
  const hasError = errors && errors.length > 0;
  const hasWarning = typeof warning === "string" && warning !== "";
  const hasEmptyOption = options.some(option => option.value === "");
  const shrinkLabel = !!value || hasEmptyOption;
  const labelId = `${id}-label`;

  return (
    <FormControl className={classes.root} {...restProps} error={hasError} aria-describedby={`${name}-error-text`}>
      {label && (
        <InputLabel variant="outlined" htmlFor={id} shrink={shrinkLabel} id={labelId}>
          {label}
        </InputLabel>
      )}
      <Select
        variant="outlined"
        value={value}
        onChange={onChange}
        renderValue={renderValue}
        displayEmpty={hasEmptyOption}
        MenuProps={{ sx: { maxHeight: 400 } }}
        labelId={labelId}
        label={label}
        notched={shrinkLabel}
      >
        {options.map(option => (
          <MenuItem
            value={optionKey(option.value)}
            key={optionKey(option.value)}
            disabled={option.disabled}
            sx={{ fontStyle: option.value === "" ? "italic" : undefined }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {(hasError || hasWarning) && (
        <FormHelperText variant="outlined" id={`${name}-error-text`}>
          {errors.map(error => error.detail).join(", ") || warning}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default React.memo(MaterialSelectField);
