import { CurrentItem } from "./types";

export function nearestItemTo(time: Date, items: CurrentItem<Date>[]) {
  let sortedItems = [...items];
  const distToTime = (t: Date) => Math.abs(t.valueOf() - time.valueOf());
  sortedItems.sort((a, b) => distToTime(a.x) - distToTime(b.x));

  return sortedItems[0];
}

export function shortenedCampaignName(campaignName: string): string {
  return campaignName.replace("-", "_").split("_").slice(0, 2).join(" ");
}
