import classNames from "classnames";
import { max } from "d3-array";
import { scaleBand, scaleLinear } from "d3-scale";
import React, { useCallback, useMemo, useRef } from "react";
import { DashboardAbsoluteKPIs } from "shared/http/apiTypes/dashboardApiTypes";
import { CurrencyCode } from "shared/utilities/isoCodes";
import mouseEventToDomPoint from "../common/mouseEventToDomPoint";
import { ShowTooltipEvent, useDebouncedEventTracking } from "../common/tracking";
import classes from "./BarChart.module.css";
import Tooltip from "./Tooltip";
import CategoricalAxes from "./charts/CategoricalAxes";
import chartClasses from "./charts/chart.module.css";
import { height, margin, width } from "./charts/dimensions";
import { categoricalDataTooltipRenderer } from "./charts/tooltipRenderers";
import { CategoricalData, SelectedBar, TooltipLocation } from "./charts/types";

export type BarChartProps = {
  series: CategoricalData;
  yAxisLabel?: string;
  tooltipRenderer?: (label: string, selectedBar: SelectedBar) => JSX.Element;
  kpi: DashboardAbsoluteKPIs;
  graphName: string;
  organizationId: string;
  currencyCode?: CurrencyCode;
};

function BarChart({
  series,
  yAxisLabel,
  tooltipRenderer = categoricalDataTooltipRenderer,
  kpi,
  organizationId,
  graphName,
  currencyCode,
}: BarChartProps) {
  const seriesValues = series["values"];
  const xValues = series["values"].map(item => item.x);

  const { maxY, x, y } = useMemo(() => {
    const allYValues = allValues(series, "y").concat(allValues(series, "y1"));

    const maxSeriesValue = Math.round((max(allYValues) as number) * 1.2);
    const maxY = maxSeriesValue > 0 ? maxSeriesValue : 1;

    const x = scaleBand().domain(xValues).rangeRound([0, width]).paddingOuter(0.2).paddingInner(0.45);

    const y = scaleLinear().domain([0, maxY]).range([height, 0]);
    return { maxY, x, y };
  }, [series, xValues]);

  const svgRef = useRef<SVGSVGElement>(null);

  const [tooltip, setTooltip] = React.useState(null as TooltipLocation | null);
  const [selectedBar, setSelectedBar] = React.useState<SelectedBar>({ xVal: "", yVal: null, prevYVal: null });

  const trackEvent = useDebouncedEventTracking(5000);

  const showTooltip = useCallback(
    (event: React.MouseEvent<SVGGElement, MouseEvent>, xVal: string, yVal: number, prevYVal: number) => {
      const svg = svgRef.current;
      if (!svg) {
        return;
      }
      const { x, y } = mouseEventToDomPoint(event, svg);
      setTooltip({
        svgX: x,
        svgY: y,
        mouseX: event.pageX,
        mouseY: event.pageY,
      });
      setSelectedBar({ xVal, yVal, prevYVal });

      trackEvent(new ShowTooltipEvent({ "Organization Id": organizationId, "Graph Name": graphName }));
    },
    [organizationId, graphName, trackEvent],
  );

  const hideTooltip = useCallback(() => {
    setTooltip(null);
  }, []);

  return (
    <div>
      {tooltip && (
        <Tooltip side={tooltip.svgX > width / 2 ? "left" : "right"} pageX={tooltip.mouseX} pageY={tooltip.mouseY}>
          {tooltipRenderer(series.label, selectedBar)}
        </Tooltip>
      )}
      <svg
        id="svg"
        className={chartClasses.svg}
        viewBox={`0 0 ${width + margin.left + margin.right} ${height + margin.bottom + margin.top}`}
        ref={svgRef}
      >
        <g transform={`translate(${margin.left},${margin.top})`}>
          {seriesValues.map(s => {
            const hoveredBar = selectedBar.xVal === s.x;
            return (
              <>
                <rect
                  className={classNames(
                    classes.bar,
                    classes.previousPeriod,
                    tooltip && hoveredBar && classes.previousPeriodHovered,
                  )}
                  onMouseMove={event => showTooltip(event, s.x, s.y, s.y1)}
                  onClick={event => showTooltip(event, s.x, s.y, s.y1)}
                  onMouseLeave={hideTooltip}
                  key={x(s.x)}
                  x={x(s.x)!}
                  y={y(s.y1)}
                  width={x.bandwidth() / 2}
                  height={height - y(s.y1)!}
                  rx={2}
                  ry={2}
                />

                <rect
                  className={classNames(
                    classes.bar,
                    classes.currentPeriod,
                    tooltip && hoveredBar && classes.currentPeriodHovered,
                  )}
                  onMouseMove={event => showTooltip(event, s.x, s.y, s.y1)}
                  onClick={event => showTooltip(event, s.x, s.y, s.y1)}
                  onMouseLeave={hideTooltip}
                  key={`${x(s.x)}999`}
                  x={x(s.x)! + x.bandwidth() / 2}
                  y={y(s.y)}
                  width={x.bandwidth() / 2}
                  height={height - y(s.y)!}
                  rx={2}
                  ry={2}
                />
              </>
            );
          })}

          {series && (
            <CategoricalAxes
              {...{
                x,
                y,
                max,
                maxY,
                yAxisLabel,
                renderYTickGuidelines: true,
                kpi,
                currencyCode,
              }}
            />
          )}
        </g>
      </svg>
    </div>
  );
}

function allValues(series: CategoricalData, k: "y" | "y1"): number[] {
  return series.values.map(val => val[k]);
}

export default React.memo(BarChart);
