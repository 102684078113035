import { Status } from "../apiTypes";
import {
  BatchCreateRequest,
  BatchCreateResponse,
  BatchUpdateRequest,
  BatchUpdateResponse,
  HasManyRelationship,
  HasOneRelationshipRequired,
  ICollectionDocument,
  PersistedResourceObject,
  UnpersistedResourceObject,
} from "../jsonApi";
import { TemplateSizeRestriction } from "./adAsset";

export type AdContentType =
  | "html_video"
  | "dpa"
  | "rich_media_interactive_html"
  | "basic_html"
  | "static"
  | "video_content"
  | "native_content"
  | "survey"
  | "survey_with_video"
  | "rich_media_with_video";

export const AdContentTypesForAdType: { [Key in AdType]: AdContentType[] } = {
  image: ["static"],
  video: ["video_content"],
  template: [
    "dpa",
    "basic_html",
    "rich_media_interactive_html",
    "html_video",
    "rich_media_with_video",
    "survey",
    "survey_with_video",
  ],
  native: ["native_content"],
};

export type AdType = "image" | "template" | "video" | "native";
export const AD_TYPE_VALUES = ["image", "template", "video", "native"] as const;
export const SUPPLY_TYPE_VALUES = [
  "null",
  "video+rewarded",
  "video",
  "native+video",
  "native",
  "html+video+rewarded",
  "html+video",
  "html+native+video",
  "html+native",
  "html",
];
export type SupplyType = (typeof SUPPLY_TYPE_VALUES)[number];

export const VIDEO_PLACEMENT_TYPE_VALUES = ["full_screen", "in_article", "in_banner", "in_feed", "in_stream"] as const;
export const IMAGE_PLACEMENT_TYPE_VALUES = ["full_screen"] as const;
export const TEMPLATE_PLACEMENT_TYPE_VALUES = ["banner", "full_screen", "leaderboard", "mid", "square"] as const;
export type PlacementType =
  | (typeof VIDEO_PLACEMENT_TYPE_VALUES)[number]
  | (typeof IMAGE_PLACEMENT_TYPE_VALUES)[number]
  | (typeof TEMPLATE_PLACEMENT_TYPE_VALUES)[number]
  | "unknown";

export const PLACEMENT_TYPES_PER_AD_TYPE: { [key in AdType]: readonly PlacementType[] } = {
  video: VIDEO_PLACEMENT_TYPE_VALUES,
  image: IMAGE_PLACEMENT_TYPE_VALUES,
  template: TEMPLATE_PLACEMENT_TYPE_VALUES,
  native: [],
} as const;

export const PLACEMENT_TYPE_VALUES: PlacementType[] = AD_TYPE_VALUES.reduce(
  (acc, adType) => {
    return Array.from(new Set([...acc, ...PLACEMENT_TYPES_PER_AD_TYPE[adType]]));
  },
  ["unknown"],
);

export type AdxStatus =
  | "adx_not_checked"
  | "adx_disapproved"
  | "adx_conditionally_approved"
  | "adx_approved"
  | "adx_unknown";

type AdxLastResponse = {
  creative_id: string;
  creative_serving_decision: Record<string, any>;
};

export interface AdsIndexAttributes {
  name: string;
  status: Status;
  target_url: string;
  ad_type: AdType;
  content_type: AdContentType;
  created_at: Date;
  updated_at: Date;
  adx_status: AdxStatus;
  adx_version: number;
  adx_last_response?: AdxLastResponse;
  extra_impression_urls: string[] | null;
  placement_types: PlacementType[];
}

type AdsRelationships = {
  campaign: HasOneRelationshipRequired;
  assets: HasManyRelationship;
  label: HasOneRelationshipRequired;
};

export type AdsCreateAttributes = {
  status?: Status;
  target_url?: string;
  ad_type: AdType;
  content_type: AdContentType;
  placement_types: PlacementType[];
  extra_impression_urls: string[];
  remove_extension_from_name?: boolean;
};

export const NATIVE_AD_WIDTH = 1200;
export const NATIVE_AD_HEIGHT = 627;

export type AdsIndexParameters = (
  | { organizationId: string; campaignIds?: string[] }
  | { organizationId?: string; campaignIds: string[] }
) & {
  adType?: AdType;
  labelIds?: string[];
  status?: Status | Status[];
  withActiveCampaigns?: boolean;
};

export type AdsIndexResource = PersistedResourceObject<AdsIndexAttributes, AdsRelationships>;
export type AdsCreateResource = UnpersistedResourceObject<AdsCreateAttributes, AdsRelationships>;

export type AdsBatchCreateRequest = BatchCreateRequest<AdsCreateAttributes, AdsRelationships>;
export type AdsBatchCreateResponse = BatchCreateResponse<AdsIndexAttributes, { assets: HasManyRelationship }>;

export type TemplateAdsCreateAttributes = Omit<AdsCreateAttributes, "ad_type"> & {
  template?: string;
  restrict_size?: TemplateSizeRestriction;
};
export type TemplateAdsCreateResource = UnpersistedResourceObject<TemplateAdsCreateAttributes, AdsRelationships>;
export type TemplateAdsBatchCreateRequest = BatchCreateRequest<TemplateAdsCreateAttributes, AdsRelationships>;
export type TemplateAdsBatchCreateResponse = BatchCreateResponse<AdsIndexAttributes, { assets: HasManyRelationship }>;

export type VideoAdsCreateAttributes = Omit<AdsCreateAttributes, "ad_type"> & {
  template?: string;
};
export type VideoAdsCreateResource = UnpersistedResourceObject<VideoAdsCreateAttributes, AdsRelationships>;
export type VideoAdsUpdateResource = PersistedResourceObject<AdsIndexAttributes, AdsRelationships>;
export type VideoAdsBatchCreateRequest = BatchCreateRequest<VideoAdsCreateAttributes, AdsRelationships>;
export type VideoAdsBatchCreateResponse = BatchCreateResponse<AdsIndexAttributes, { assets: HasManyRelationship }>;

export type NumericString = string;
export type NativeAdsCreateAttributes = Omit<AdsCreateAttributes, "ad_type" | "placement_types"> & {
  title: string;
  description: string;
  rating: NumericString;
  cta_text: string;
};
export type NativeAdsCreateResource = UnpersistedResourceObject<NativeAdsCreateAttributes, AdsRelationships>;
export type NativeAdsBatchCreateRequest = BatchCreateRequest<NativeAdsCreateAttributes, AdsRelationships>;
export type NativeAdsBatchCreateResponse = BatchCreateResponse<AdsIndexAttributes, { assets: HasManyRelationship }>;

export type AdsIndexResponse = ICollectionDocument<AdsIndexResource>;

export type AdsBatchUpdateAttributes = Partial<{
  status: Status;
  target_url: string | null;
  template_value: string;
  label_id: string;
  extra_impression_urls: string[];
  placement_types: PlacementType[];
  name: string;
}>;

export type AdsBatchUpdateRequest = BatchUpdateRequest<AdsBatchUpdateAttributes>;

export type AdsBatchUpdateResponse = BatchUpdateResponse<AdsIndexResource>;

export type AdsUpdateRequestResource = PersistedResourceObject<Partial<AdsIndexAttributes>, Partial<AdsRelationships>>;

export type AdsCopyToCampaignsRequestAttributes = {
  ad_ids: string[];
  campaign_ids: string[];
};

export type AdsCopyToCampaignsResponse = { data: AdsIndexResource[] };
