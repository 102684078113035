import { Icon } from "@mui/material";
import classNames from "classnames";
import { DashboardAbsoluteKPIs } from "shared/http/apiTypes/dashboardApiTypes";
import { i18n } from "shared/utilities/I18nContext";
import { CurrencyCode } from "shared/utilities/isoCodes";
import { formatIsoDate } from "../../common/formatDate";
import formatNumber, { formatDollars } from "../../common/formatNumber";
import formatShortDate from "../../common/formatShortDate";
import LegendIcon from "./LegendIcon";
import chartClasses from "./chart.module.css";
import { Formatter, SelectedBar, TimeseriesTooltipRenderer, TooltipItems } from "./types";

export const campaignTooltipRenderer: TimeseriesTooltipRenderer = (
  time: Date,
  tooltipItems: TooltipItems,
  valueFormatter: Formatter,
  kpi?: DashboardAbsoluteKPIs,
  currencyCode?: CurrencyCode,
) => {
  return (
    <div className={chartClasses.campaignTooltipContainer}>
      <h5 className={chartClasses.campaignTooltipTitle}>{formatIsoDate(time)}</h5>
      {Object.entries(tooltipItems).map(([campaignName, { value, color }]) => {
        return (
          <>
            <div
              key={campaignName}
              className={classNames(chartClasses.tooltipNumbers, chartClasses.campaignTooltipItem)}
            >
              <LegendIcon color={color} />
              <div className={chartClasses.campaignName}>{campaignName}</div>
              <div>
                {kpi === "buying_price"
                  ? i18n.formatMoney({ value, currencyCode: currencyCode || "USD" }, "short")
                  : valueFormatter(value)}
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export const defaultLineChartTooltipRenderer = (time: Date, tooltipItems: TooltipItems, valueFormatter: Formatter) => {
  return (
    <>
      <h4>{formatShortDate(time)}</h4>
      {Object.entries(tooltipItems).map(([title, { value, color }]) => (
        <p key={title}>
          <LegendIcon color={color} /> {title}: {valueFormatter(value)}
        </p>
      ))}
    </>
  );
};

export const categoricalDataTooltipRenderer = (dataLabel: string, selectedBar: SelectedBar) => {
  if (!selectedBar.yVal || !selectedBar.prevYVal) return <div />;

  const formattedValue = dataLabel === "Cost" ? formatDollars(selectedBar.yVal) : formatNumber(selectedBar.yVal);
  const formattedPreviousValue =
    dataLabel === "Cost" ? formatDollars(selectedBar.prevYVal) : formatNumber(selectedBar.prevYVal);

  const percentageChange = (selectedBar.yVal - selectedBar.prevYVal) / selectedBar.prevYVal;

  const percentageChangeDisplay = percentageChange && (
    <span className={percentageChange < 0 ? chartClasses.negativeChange : chartClasses.positiveChange}>
      <Icon>{percentageChange > 0 ? "arrow_upward" : "arrow_downward"}</Icon>
      {`(${formatNumber(Math.abs(percentageChange))})`}
    </span>
  );

  let title: React.ReactNode;
  if (selectedBar.xVal.includes("others:")) {
    const categoryLabel = selectedBar.xVal.split(":");
    title = (
      <>
        <div className={chartClasses.tooltipTitle}>
          <h5>
            {categoryLabel[0]}
            {percentageChangeDisplay}
          </h5>
        </div>
        <div className={chartClasses.tooltipSubtitle}>{categoryLabel[1]}</div>
      </>
    );
  } else {
    title = (
      <div className={chartClasses.tooltipTitle}>
        <h5>
          {selectedBar.xVal}
          {percentageChangeDisplay}
        </h5>
      </div>
    );
  }
  return (
    <>
      {title}
      <div className={chartClasses.tooltipNumbers}>
        <div className={chartClasses.tooltipNumbersSubtitle}>Current period:</div>
        <div>{formattedValue}</div>
      </div>

      <div className={chartClasses.tooltipNumbers}>
        <div className={chartClasses.tooltipNumbersSubtitle}>Previous period:</div>
        <div>{formattedPreviousValue}</div>
      </div>
    </>
  );
};
