import { Button as MaterialButton, ButtonProps as MaterialButtonProps, buttonClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(MaterialButton)({
  borderRadius: 4,
  padding: "5px 20px",
  textTransform: "none",
  lineHeight: 1.2,

  [`& .${buttonClasses.startIcon}`]: {
    marginLeft: 0,
    marginRight: 20,
  },
  [`& .${buttonClasses.endIcon}`]: {
    marginLeft: 20,
    marginRight: 0,
  },
});

function Button(props: MaterialButtonProps) {
  return <StyledButton {...props} />;
}

export default Button;
