// organize-imports-ignore
import "shared/utilities/global-polyfill";
import ReactDOM from "react-dom";
import "regenerator-runtime";
import "./index.css";
import AppContext from "./components/AppContext";
import App from "./App";

const rootElement = document.getElementById("root") as HTMLElement;
ReactDOM.render(
  <AppContext>
    <App />
  </AppContext>,
  rootElement,
);
