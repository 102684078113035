import { Box } from "@mui/system";
import { Moment } from "moment-timezone";
import { useState } from "react";
import DateRangeSelect, { DateRange } from "shared/components/DateRangeSelect";
import DateRangeButton from "./DateRangeButton/DateRangeButton";

export type MaterialDateRangeSelectProps = {
  value: DateRange;
  okButtonTitle?: string;
  onChange(range: DateRange): void;
  onClose?(): void;
  isOutsideRange?: (day: Moment) => boolean;
  renderTriggerButton?: ButtonRenderer;
};

function MaterialDateRangeSelect({
  value: { startDate, endDate },
  okButtonTitle,
  onChange,
  onClose,
  isOutsideRange,
  renderTriggerButton = renderDefaultButton,
}: MaterialDateRangeSelectProps) {
  // State
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  return (
    <>
      {renderTriggerButton({ startDate, endDate }, openCalendarIfNeeded)}

      <Box display="none">
        <DateRangeSelect
          startDate={startDate}
          endDate={endDate}
          okButtonTitle={okButtonTitle}
          onChange={saveDateRangeAndClose}
          isOpen={isCalendarOpen}
          isOutsideRange={isOutsideRange}
          withPortal
          appendToBody
          onClose={closeWithoutSaving}
        />
      </Box>
    </>
  );

  function openCalendarIfNeeded() {
    if (!isCalendarOpen) setIsCalendarOpen(true);
  }

  function saveDateRangeAndClose(dateRange: DateRange) {
    setIsCalendarOpen(false);
    onChange(dateRange);
  }

  function closeWithoutSaving() {
    setIsCalendarOpen(false);
    onClose?.();
  }
}

type ButtonRenderer = (dateRange: DateRange, onClick: () => void) => JSX.Element;

const renderDefaultButton: ButtonRenderer = (dateRange, onClick) => (
  <DateRangeButton dateRange={dateRange} size="small" onClick={onClick} />
);

export default MaterialDateRangeSelect;
