import { ButtonProps, styled } from "@mui/material";
import { useContext } from "react";
import { I18nContext } from "shared/utilities/I18nContext";
import Button from "../Button";
import { DateRange } from "../DateRangeSelect";
import CalendarIcon from "../icons/CalendarIcon";

export type DateRangeButtonProps = {
  dateRange: DateRange;
  size?: ButtonProps["size"];
  onClick: ButtonProps["onClick"];
};

function DateRangeButton({ dateRange, size, onClick }: DateRangeButtonProps) {
  const i18n = useContext(I18nContext);
  const hasText = Boolean(dateRange.startDate || dateRange.endDate);

  return (
    <Root hasText={hasText} size={size} variant="naked" startIcon={<CalendarIcon />} sx={{ px: 1 }} onClick={onClick}>
      {i18n.formatDateRange(dateRange)}
    </Root>
  );
}

const Root = styled(Button, { shouldForwardProp: prop => prop !== "hasText" })(({ hasText }: { hasText: boolean }) => ({
  "& .MuiButton-startIcon": {
    marginRight: hasText ? 10 : 0,
  },
}));

export default DateRangeButton;
