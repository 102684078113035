import React from "react";
import TrackingService, { ITrackingService, NullTrackingServiceAdapter } from "shared/services/TrackingService";

const TrackingContext = React.createContext<ITrackingService>(new TrackingService(new NullTrackingServiceAdapter()));

const { Provider: TrackingProvider, Consumer: TrackingConsumer } = TrackingContext;

export interface TrackingProps {
  trackingService: ITrackingService;
}

function withTracking<Props extends TrackingProps>(WrappedComponent: React.ComponentType<Props>) {
  return (props: Omit<Props, keyof TrackingProps>) => (
    <TrackingConsumer>
      {trackingService => <WrappedComponent trackingService={trackingService} {...(props as any)} />}
    </TrackingConsumer>
  );
}

export default TrackingContext;
export { TrackingConsumer, TrackingProvider, withTracking };
