import classnames from "classnames";
import { ScaleLinear, ScaleTime } from "d3-scale";
import React, { useEffect } from "react";
import { CurrencyCode } from "shared/utilities/isoCodes";
import classes from "./Axes.module.css";
import TimeXAxis from "./TimeXAxis";
import YAxis from "./YAxis";

type Props = {
  x: ScaleTime<number, number>;
  y: ScaleLinear<number, number>;
  minX: Date;
  maxX: Date;
  maxY: number;
  yAxisLabel?: string;
  renderYTickGuidelines?: boolean;
  currencyCode?: CurrencyCode;
};

function Axes({ x, y, minX, maxX, maxY, yAxisLabel, renderYTickGuidelines, currencyCode }: Props) {
  const [xAxisMargin, setXaxisMargin] = React.useState<number>(0);
  const [yAxisMargin, setYaxisMargin] = React.useState<number>(0);

  useEffect(() => {
    const windowWidth = document.body.clientWidth;
    if (windowWidth < 400) {
      setXaxisMargin(20);
      setYaxisMargin(-25);
    }
  }, []);

  return (
    <g>
      <line
        key={`yAxisLine`}
        x1={x(minX)}
        y1={y(maxY)}
        x2={x(minX)}
        y2={y(0)}
        className={classnames(classes.line, classes.axisLine)}
      />
      <line
        key={`xAxisLine`}
        x1={x(minX)}
        y1={y(0)}
        x2={x(maxX)}
        y2={y(0)}
        className={classnames(classes.line, classes.axisLine)}
      />
      <g transform={`translate(${yAxisMargin},${0})`}>
        <YAxis y={y} maxSvgX={x(maxX)!} renderGuideLines={renderYTickGuidelines} currencyCode={currencyCode} />
      </g>
      <g transform={`translate(${0},${y(0)! + xAxisMargin})`}>
        <TimeXAxis x={x} />
      </g>
      <g transform={`translate(${x(minX)! - 80},${y(maxY / 3)}) rotate(-90)`}>
        <text x={0} y={0}>
          {yAxisLabel}
        </text>
      </g>
    </g>
  );
}

export default React.memo(Axes);
