import { useEffect } from "react";

const shortcutsMap = Object.freeze({
  escape: (event: KeyboardEvent) => event.key === "Escape",
  globalSearch: (event: KeyboardEvent) => event.metaKey && event.key === "k",
});
type KeyBoardShortCuts = keyof typeof shortcutsMap;

export const useGlobalKeyboardShortcut = ({
  shortcut,
  action,
}: {
  shortcut: KeyBoardShortCuts;
  action: () => void;
}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const shouldHandle = shortcutsMap[shortcut](event);

      if (shouldHandle) {
        event.preventDefault();
        action();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });
};

export const useGlobalEscapeKeyPress = (action: () => void) =>
  useGlobalKeyboardShortcut({ shortcut: "escape", action });
