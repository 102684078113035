import classNames from "classnames";
import classes from "./KPIButton.module.css";

type KPIButtonProps = {
  selectedKPI: string;
  onClick: () => void;
  kpi: keyof typeof buttonKPILabels;
};

export const buttonKPILabels = {
  impressions: "Impressions",
  clicks: "Clicks",
  ctr: "CTR",
  buying_price: "Cost",
  ap_conversions: "AP Conversions",
};

export type ButtonKPIs = keyof typeof buttonKPILabels;

export default function KPIButton(props: KPIButtonProps) {
  const { selectedKPI, kpi, onClick } = props;

  return (
    <button
      className={classNames(classes.button, kpi === selectedKPI ? classes.selectedButton : classes.buttonNonSelected)}
      onClick={onClick}
    >
      {buttonKPILabels[kpi]}
    </button>
  );
}
