import { ThemeProvider } from "@mui/system";
import RemergeApi from "shared/http/RemergeApi";
import { RemergeAPIProvider } from "shared/http/remergeApiContext";
import HttpService from "shared/services/HttpService";
import { TrackingProvider } from "shared/services/TrackingContext";
import TrackingService, {
  NullTrackingServiceAdapter,
  ProductionTrackingServiceAdapter,
} from "shared/services/TrackingService";
import { theme } from "shared/utilities/theme";
type AppContextProps = React.PropsWithChildren<{}>;

const httpService = new HttpService(import.meta.env.VITE_API_HOST as string);
const remergeApi = new RemergeApi(httpService);
const trackingService = new TrackingService(
  import.meta.env.VITE_MIXPANEL_ACCESS_TOKEN
    ? new ProductionTrackingServiceAdapter(import.meta.env.VITE_MIXPANEL_ACCESS_TOKEN)
    : new NullTrackingServiceAdapter(),
);

export default function AppContext(props: AppContextProps) {
  return (
    <RemergeAPIProvider value={remergeApi}>
      <TrackingProvider value={trackingService}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </TrackingProvider>
    </RemergeAPIProvider>
  );
}
