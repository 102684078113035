import { Icon } from "@mui/material";
import classNames from "classnames";
import { useContext, useState } from "react";
import { ISelectOption } from "shared/components/ISelectOption";
import MaterialSelectField from "shared/components/form/MaterialSelectField";
import { CombinedDataAttributes, DashboardAbsoluteKPIs } from "shared/http/apiTypes/dashboardApiTypes";
import TrackingContext from "shared/services/TrackingContext";
import { CurrencyCode } from "shared/utilities/isoCodes";
import { ChangeKpiEvent } from "../common/tracking";
import { GroupedTimeSeries, transformDataForLineChart } from "../common/transformData";
import classes from "./Dashboard.module.css";
import DashboardDataTable from "./DashboardDataTable";
import KPIButton, { ButtonKPIs, buttonKPILabels } from "./KPIButton";
import LineChart from "./LineChart";
import LoadingBars from "./LoadingBars";
import { NoDataBanner } from "./NoDataBanner";
import Legend from "./charts/Legend";
import { campaignTooltipRenderer } from "./charts/tooltipRenderers";
import { Series } from "./charts/types";

type CampaignReportProps = {
  isMobile: boolean;
  selectedClient: string;
  groupedCampaignTimeSeries?: GroupedTimeSeries;
  audienceOptions: ISelectOption[];
  selectedAudience: string;
  setSelectedAudience: (audience: string) => void;
  uniqueAudiences: Set<string>;
  campaignDataForTable: CombinedDataAttributes[];
  currencyCode: CurrencyCode;
};

export function CampaignReport({
  isMobile,
  selectedClient,
  groupedCampaignTimeSeries,
  audienceOptions,
  selectedAudience,
  setSelectedAudience,
  uniqueAudiences,
  campaignDataForTable,
  currencyCode,
}: CampaignReportProps) {
  const trackingService = useContext(TrackingContext);
  const [campaignKPI, setCampaignKPI] = useState<DashboardAbsoluteKPIs>("clicks");
  const [campaignDataTableVisible, setCampaignDataTableVisible] = useState<boolean>(!isMobile);

  const onCampaignKPIChange = (newKPI: DashboardAbsoluteKPIs) => {
    setCampaignKPI(newKPI);
    trackingService.track(
      new ChangeKpiEvent({
        "Graph Name": "Campaign Report",
        "Organization Id": selectedClient,
        "KPI Name": buttonKPILabels[newKPI],
      }),
    );
  };

  const campaignSeries: Series[] | undefined = groupedCampaignTimeSeries
    ? transformDataForLineChart(groupedCampaignTimeSeries, campaignKPI)
    : undefined;

  return (
    <>
      <div className={classNames(classes.campaignTypePerformance, classes.spacingTop)}></div>
      <div className={classNames(classes.campaignTypePerformance, classes.sectionTitle, classes.sectionPadding)}>
        <h4>Campaign Report</h4>
      </div>
      <div
        className={classNames(
          classes.campaignTypePerformance,
          classes.sectionNumbers,
          classes.sectionPadding,

          { [classes.chartSectionLoading]: !groupedCampaignTimeSeries },
        )}
      >
        {groupedCampaignTimeSeries && Object.keys(groupedCampaignTimeSeries).length === 0 ? (
          <NoDataBanner />
        ) : (
          <>
            {groupedCampaignTimeSeries ? (
              <>
                <div className={classes.campaignSubTitleContainer}>
                  <div className={classes.selectAudience}>
                    {uniqueAudiences.size > 1 ? (
                      <MaterialSelectField
                        errors={[]}
                        id="audience-selector"
                        label=""
                        name="audience-selector"
                        options={audienceOptions}
                        value={selectedAudience}
                        onChange={newValue => {
                          setSelectedAudience(newValue.toString());
                        }}
                        disabled={uniqueAudiences.size === 0}
                      />
                    ) : (
                      selectedAudience
                    )}
                  </div>
                  <div className={classNames(classes.campaignTypePerformance, classes.sectionPadding, classes.buttons)}>
                    {Object.keys(buttonKPILabels).map((kpi: ButtonKPIs) => {
                      return (
                        <KPIButton
                          key={kpi}
                          kpi={kpi}
                          selectedKPI={campaignKPI}
                          onClick={() => onCampaignKPIChange(kpi)}
                        />
                      );
                    })}
                  </div>
                </div>
                <LineChart
                  series={campaignSeries as Series[]}
                  tooltipRenderer={campaignTooltipRenderer}
                  kpi={campaignKPI}
                  graphName="Campaign Report"
                  organizationId={selectedClient}
                  currencyCode={campaignKPI === "buying_price" ? currencyCode : undefined}
                />
                <div className={classes.legend}>
                  <Legend series={campaignSeries as Series[]} orientation="vertical" />
                </div>
                <div
                  className={classNames(classes.campaignTypePerformance, classes.summary)}
                  onClick={() => setCampaignDataTableVisible(!campaignDataTableVisible)}
                >
                  <div>Summary</div>
                  <div>
                    <Icon>{campaignDataTableVisible ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                  </div>
                </div>
                <div className={classNames(classes.campaignTypePerformance, classes.sectionTable)}>
                  <div
                    className={classNames(
                      classes.dataTable,
                      campaignDataTableVisible ? classes.visible : classes.hidden,
                    )}
                  >
                    <DashboardDataTable data={campaignDataForTable} theme="light" />
                  </div>
                </div>
              </>
            ) : (
              <LoadingBars />
            )}
          </>
        )}
      </div>
    </>
  );
}
