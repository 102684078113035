import { useEffect, useState } from "react";
import { IdTokenUser } from "shared/http/id-token";
import { DEFAULT_ORG_ID } from "../components/Dashboard";

export const useSelectedClient = ({ user }: { user: IdTokenUser }) => {
  const [selectedClient, setSelectedClient] = useState<string>(() => {
    const userOrgs = user.organizations.map(org => org.toString());

    const queryParams = new URLSearchParams(window.location.search);
    const storedOrgId = queryParams.get("organizationId") || window.localStorage.getItem("organizationId");

    if (storedOrgId && (userOrgs.includes(storedOrgId) || user.admin)) return storedOrgId;

    const organizationId = user.admin ? DEFAULT_ORG_ID : userOrgs[0];

    window.localStorage.setItem("organizationId", organizationId);

    return organizationId;
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("organizationId", selectedClient);
    history.replaceState(null, "", "?" + queryParams.toString());
  }, [selectedClient]);

  return {
    selectedClient,
    setSelectedClient,
  };
};
