import classNames from "classnames";
import { ExecutiveSummaryData } from "shared/http/apiTypes/dashboardApiTypes";
import { CurrencyCode } from "shared/utilities/isoCodes";
import classes from "./Dashboard.module.css";
import KPITile, { OverviewKPIs, executiveSummaryLabels } from "./KPITile";
import LoadingBars from "./LoadingBars";
import { NoDataBanner } from "./NoDataBanner";

type OverviewProps = {
  totals?: ExecutiveSummaryData;
  previousTotals?: ExecutiveSummaryData;
  currencyCode: CurrencyCode;
};

const kpiValues = Object.keys(executiveSummaryLabels);

export function KPITiles({ totals, previousTotals, currencyCode }: OverviewProps) {
  return (
    <>
      {totals && previousTotals ? (
        <div className={classes.kpis}>
          {kpiValues.map((key: OverviewKPIs) => {
            return (
              <KPITile
                key={key}
                kpi={key}
                currentPeriodNumber={totals[key]}
                previousPeriodNumber={previousTotals[key]}
                currencyCode={currencyCode}
              />
            );
          })}
        </div>
      ) : (
        <LoadingBars />
      )}
    </>
  );
}

export function Overview({ totals, previousTotals, currencyCode }: OverviewProps) {
  return (
    <div>
      <div className={classNames(classes.executiveSummary, classes.spacingTop)}></div>
      <div className={classNames(classes.executiveSummary, classes.title)}>
        <h4>Overview</h4>
      </div>
      <div
        className={classNames(classes.executiveSummary, classes.numbers, {
          [classes.overviewSectionLoading]: !(totals && previousTotals),
        })}
      >
        {totals && totals["buying_price"] === 0 ? (
          <NoDataBanner />
        ) : (
          <KPITiles totals={totals} previousTotals={previousTotals} currencyCode={currencyCode} />
        )}
      </div>
    </div>
  );
}
