import { ScaleTime } from "d3-scale";
import React from "react";
import formatShortDate from "../../common/formatShortDate";
import axesClasses from "./Axes.module.css";
import { tickLength } from "./dimensions";

function TimeXAxis({ x }: { x: ScaleTime<number, number> }) {
  const tickDates = x.ticks(6).map(castToUTCDateBoundary);
  const formater = formatShortDate;
  return (
    <>
      {tickDates.map(t => (
        <g key={`tick/${t}`}>
          <line x1={x(t)!} x2={x(t)!} y1={0} y2={tickLength} className={axesClasses.line} />
          <text x={x(t)! + 2} y={tickLength + 15} className={axesClasses.xLabels}>
            {formater(t)}
          </text>
        </g>
      ))}
    </>
  );
}

function castToUTCDateBoundary(date: Date): Date {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), -date.getTimezoneOffset() / 60);
}

export default React.memo(TimeXAxis);
