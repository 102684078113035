import classNames from "classnames";
import { useContext, useState } from "react";
import { DashboardAbsoluteKPIs } from "shared/http/apiTypes/dashboardApiTypes";
import TrackingContext from "shared/services/TrackingContext";
import { CurrencyCode } from "shared/utilities/isoCodes";
import { ChangeKpiEvent } from "../common/tracking";
import BarChart from "./BarChart";
import classes from "./Dashboard.module.css";
import KPIButton, { ButtonKPIs, buttonKPILabels } from "./KPIButton";
import { executiveSummaryLabels } from "./KPITile";
import LoadingBars from "./LoadingBars";
import { NoDataBanner } from "./NoDataBanner";
import { CategoricalData } from "./charts/types";

type InventoryReportProps = {
  selectedClient: string;
  inventorySeriesLoaded?: boolean;
  inventorySeriesValues?: any;
  currencyCode: CurrencyCode;
};

export function InventoryReport({
  inventorySeriesLoaded,
  inventorySeriesValues,
  selectedClient,
  currencyCode,
}: InventoryReportProps) {
  const [inventoryDataKPI, setInventoryDataKPI] = useState<DashboardAbsoluteKPIs>("impressions");
  const trackingService = useContext(TrackingContext);

  const barChartSeries = inventorySeriesValues
    ? {
        label: executiveSummaryLabels[inventoryDataKPI],
        color: "#d2c8ff",
        values: inventorySeriesValues[inventoryDataKPI],
      }
    : undefined;

  const onInventoryDataKPIChange = (newKPI: DashboardAbsoluteKPIs) => {
    setInventoryDataKPI(newKPI);
    trackingService.track(
      new ChangeKpiEvent({
        "Graph Name": "Inventory Report",
        "Organization Id": selectedClient,
        "KPI Name": buttonKPILabels[newKPI],
      }),
    );
  };

  const noData = barChartSeries && Object.keys(barChartSeries).length === 0;

  return (
    <>
      <div className={classNames(classes.inventoryReport, classes.spacingTop, classes.sectionPadding)}></div>
      <div className={classNames(classes.inventoryReport, classes.sectionTitle, classes.sectionPadding)}>
        <h4>Inventory Report</h4>

        {noData ? null : (
          <>
            <h5 className={classNames(classes.inventoryReport, classes.subHeader)}>
              Top 10 Supply Partners by Impressions
            </h5>
            <div className={classNames(classes.inventoryReport, classes.buttons, classes.sectionPadding)}>
              {Object.keys(buttonKPILabels).map((kpi: ButtonKPIs) => {
                return (
                  <KPIButton
                    key={kpi}
                    kpi={kpi}
                    selectedKPI={inventoryDataKPI}
                    onClick={() => onInventoryDataKPIChange(kpi)}
                  />
                );
              })}
            </div>
          </>
        )}
      </div>

      {noData ? (
        <NoDataBanner />
      ) : (
        <>
          <div
            className={classNames(
              classes.inventoryReport,
              classes.sectionNumbers,
              classes.sectionPadding,

              { [classes.chartSectionLoading]: !inventorySeriesLoaded },
            )}
          >
            {inventorySeriesLoaded ? (
              <BarChart
                series={barChartSeries as CategoricalData}
                kpi={inventoryDataKPI}
                graphName="Inventory Report"
                organizationId={selectedClient}
                currencyCode={inventoryDataKPI === "buying_price" ? currencyCode : undefined}
              />
            ) : (
              <LoadingBars />
            )}
          </div>
        </>
      )}
    </>
  );
}
