import classnames from "classnames";
import { ScaleLinear } from "d3-scale";
import React from "react";
import { i18n } from "shared/utilities/I18nContext";
import { CurrencyCode } from "shared/utilities/isoCodes";
import formatNumber from "../../common/formatNumber";
import axesClasses from "./Axes.module.css";
import classes from "./YAxis.module.css";
import { tickLength } from "./dimensions";

type Props = {
  y: ScaleLinear<number, number>;
  maxSvgX: number;
  renderGuideLines?: boolean;
  currencyCode?: CurrencyCode;
};

function YAxis({ y, maxSvgX, renderGuideLines, currencyCode }: Props) {
  const valueTicks = y.ticks(3);
  return (
    <>
      {valueTicks.map(v => {
        const label = currencyCode
          ? i18n.formatMoney({ value: v, currencyCode: currencyCode || "USD" }, "short")
          : formatNumber(v, true);
        return (
          <g key={`y-tick/${v}`}>
            <line x1={0} x2={-tickLength} y1={y(v)!} y2={y(v)!} className={axesClasses.line} />
            {renderGuideLines && v !== 0 && (
              <line
                strokeDasharray="5,15"
                className={classnames(axesClasses.line, classes.fineDash)}
                x1={0}
                x2={maxSvgX}
                y1={y(v)}
                y2={y(v)}
              />
            )}

            <text className={classes.yLabel} x={-(tickLength * 2 + label.length * 6)} y={y(v)! + 5}>
              {label}
            </text>
          </g>
        );
      })}
    </>
  );
}

export default React.memo(YAxis);
