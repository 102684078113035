import { useContext, useEffect, useState } from "react";

import { ErrorBoundary } from "react-error-boundary";
import { IdToken, parseIdToken, removeIdToken } from "shared/http/id-token";
import { NullRollbarService, ProductionRollbarService } from "shared/services/RollbarService";
import TrackingContext from "shared/services/TrackingContext";
import Centered from "./components/Centered";
import Dashboard, { dashboardOrgIds } from "./components/Dashboard";
import ErrorMessage from "./components/ErrorMessage";

type AppProps = {};

const rollbarService =
  process.env.NODE_ENV === "production" ? new ProductionRollbarService() : new NullRollbarService();

const loginBaseURL = import.meta.env.VITE_LOGIN_URL as string;
const reportsURL = import.meta.env.VITE_REPORTS_URL as string;

export default function App(_props: AppProps) {
  const [idToken, setIdToken] = useState<IdToken | undefined>(parseIdToken);
  const trackingService = useContext(TrackingContext);

  const logOut = () => {
    removeIdToken();
    setIdToken(undefined);
    trackingService.logout();
    window.localStorage.removeItem("organizationId");
  };

  useEffect(() => {
    if (idToken) {
      trackingService.identify(idToken.user.id.toString(), idToken.user.name);
    }
  }, [idToken, trackingService]);

  if (!idToken || !idToken.user.id) {
    redirectToLogin();
    return null;
  }

  if (!userHasAccess(idToken)) {
    redirectToReports();
    return null;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPage} onError={onError}>
      <Dashboard user={idToken.user} logOut={logOut} />
    </ErrorBoundary>
  );
}

function onError(error: Error) {
  rollbarService.error(error);
  return;
}

function userHasAccess(idToken: IdToken) {
  return idToken.user.admin || idToken.user.organizations.some(id => dashboardOrgIds.includes(id.toString()));
}

function redirectToLogin() {
  const loginPath = `${loginBaseURL}?redirectTo=${window.location.href}`;

  window.location.href = loginPath;
}

function redirectToReports() {
  window.location.href = reportsURL;
}

function ErrorPage(_props: {}) {
  return (
    <Centered>
      <ErrorMessage
        errorMessage="Something went wrong"
        callToAction="Reload Page"
        onCallToActionClick={() => window.location.reload()}
      />
    </Centered>
  );
}
