import ObeyMeLogo from "@dashboard/assets/Obey-Me-logo.jpeg";
import UmaMusumeLogo from "@dashboard/assets/Uma-Musume-Logo.png";
import ActivisionLogo from "@dashboard/assets/activision-logo.png";
import CKLogo from "@dashboard/assets/ck-logo.png";
import FavorLogo from "@dashboard/assets/favor-logo.png";
import LawsonLogo from "@dashboard/assets/lawson-logo.png";
import MahJongLogo from "@dashboard/assets/mah-jong-logo.jpeg";
import PlayartLogo from "@dashboard/assets/nhn-playart-logo.png";
import PrincessConnectLogo from "@dashboard/assets/princess-connect-logo.png";
import RakutenLogo from "@dashboard/assets/rakuten-logo.png";
import SelfLogo from "@dashboard/assets/self-logo.png";
import classNames from "classnames";
import { useMemo, useState } from "react";
import { DashboardAbsoluteKPIs } from "shared/http/apiTypes/dashboardApiTypes";
import { IdTokenUser } from "shared/http/id-token";
import { CurrencyCode } from "shared/utilities/isoCodes";
import { useDashboardData } from "../common/useDashboardData";
import useDateRange from "../common/useDateRange";
import { useSelectedClient } from "../common/useSelectedClient";
import { CampaignReport } from "./CampaignReport";
import { CreativeReport } from "./CreativeReport";
import classes from "./Dashboard.module.css";
import Header from "./Header";
import { InventoryReport } from "./InventoryReport";
import { Overview } from "./Overview";
import ReportLinks from "./ReportLinks";

type DashboardProps = {
  user: IdTokenUser;
  logOut(): void;
};

export const DEFAULT_ORG_ID = "842";

export const INVENTORY_REPORT_ENABLED_ORGS = ["842", "365"];

const clientOptions: Array<{ value: string; label: string; logo: string; currencyCode: CurrencyCode }> = [
  { value: "842", label: "Credit Karma", logo: CKLogo, currencyCode: "USD" },
  { value: "1553", label: "Self", logo: SelfLogo, currencyCode: "USD" },
  { value: "461", label: "Favor", logo: FavorLogo, currencyCode: "USD" },
  { value: "365", label: "Activision", logo: ActivisionLogo, currencyCode: "USD" },
  { value: "1611", label: "Lawson_Japan", logo: LawsonLogo, currencyCode: "JPY" },
  { value: "1458", label: "NTT Solmare Corp._Obey Me!", logo: ObeyMeLogo, currencyCode: "JPY" },
  { value: "1583", label: "KONAMI_MAH-JONG FIGHT CLUB Sp", logo: MahJongLogo, currencyCode: "JPY" },
  { value: "715", label: "Rakuten Group, Inc._Rakuten Pay", logo: RakutenLogo, currencyCode: "JPY" },
  { value: "1331", label: "Cygames_Umamusume", logo: UmaMusumeLogo, currencyCode: "JPY" },
  { value: "1643", label: "Cygames_Princess Connect! Re:Dive", logo: PrincessConnectLogo, currencyCode: "JPY" },
  { value: "1523", label: "NHN PlayArt_Compass", logo: PlayartLogo, currencyCode: "JPY" },
];

export const dashboardOrgIds = clientOptions.map(clientOption => clientOption.value);

function Dashboard({ user, logOut }: DashboardProps) {
  const { dateRange, onDateRangeChange, prevDateRange } = useDateRange();

  const userOrganizations = user.organizations.map(org => org.toString());

  const multipleOrgs = userOrganizations.length > 1 || user.admin;

  const availableClientOptions = user.admin
    ? clientOptions
    : clientOptions.filter(client => userOrganizations.includes(client.value));

  const isMobile = document.body.clientWidth < 800;

  const [selectedAudience, setSelectedAudience] = useState("");

  const { selectedClient, setSelectedClient } = useSelectedClient({ user });
  const selectedClientOption = availableClientOptions.find(option => option.value === selectedClient);
  const currencyCode = selectedClientOption?.currencyCode || "USD";
  const {
    totals,
    previousTotals,
    inventorySeriesValues,
    audienceOptions,
    uniqueAudiences,
    groupedCampaignTimeSeries,
    campaignDataForTable,
    adTypeApConversionsSeries,
    adTypeAPConversionsData,
    adTypeSpendSeries,
    adTypeBuyingPriceData,
  } = useDashboardData({
    organizationId: selectedClient,
    selectedAudience,
    setSelectedAudience,
    dateRange,
    prevDateRange,
    currencyCode,
  });

  const inventorySeriesLoaded =
    inventorySeriesValues &&
    Object.keys(inventorySeriesValues).some((key: DashboardAbsoluteKPIs) => {
      return inventorySeriesValues[key].length > 0;
    });

  const onSetSelectedClient = (organizationId: string) => {
    setSelectedClient(organizationId);
    const cacheEntry = {
      organizationId,
      timestamp: Date.now(),
    };

    window.localStorage.setItem("organization", JSON.stringify(cacheEntry));
  };

  const inventoryReportEnabled = useMemo(
    () =>
      INVENTORY_REPORT_ENABLED_ORGS.includes(selectedClient) ||
      user.detailed_report_organizations?.includes(parseInt(selectedClient)),
    [selectedClient, user],
  );

  return (
    <>
      <div className={classNames(classes.container)}>
        <Header
          multipleOrgs={multipleOrgs}
          dateRange={dateRange}
          clientOptions={availableClientOptions}
          selectedClient={selectedClient}
          setSelectedClient={onSetSelectedClient}
          setDateRange={onDateRangeChange}
          previousDateRange={{ startDate: prevDateRange.startDate, endDate: prevDateRange.endDate }}
          logOut={logOut}
        />
        <div className={classes.gridContainer}>
          <div className={classNames(classes.section, classes.sectionPadding)}>
            <Overview totals={totals} previousTotals={previousTotals} currencyCode={currencyCode} />
          </div>

          {inventoryReportEnabled && (
            <div className={classes.section}>
              <InventoryReport
                inventorySeriesLoaded={inventorySeriesLoaded}
                inventorySeriesValues={inventorySeriesValues}
                selectedClient={selectedClient}
                currencyCode={currencyCode}
              />
            </div>
          )}

          <div className={classes.section}>
            <CampaignReport
              isMobile={isMobile}
              selectedClient={selectedClient}
              selectedAudience={selectedAudience}
              setSelectedAudience={setSelectedAudience}
              audienceOptions={audienceOptions}
              uniqueAudiences={uniqueAudiences}
              campaignDataForTable={campaignDataForTable}
              groupedCampaignTimeSeries={groupedCampaignTimeSeries}
              currencyCode={currencyCode}
            />
          </div>

          <div className={classNames(classes.section, classes.overflowHidden)}>
            <CreativeReport
              isMobile={isMobile}
              selectedClient={selectedClient}
              adTypeApConversionsSeries={adTypeApConversionsSeries}
              adTypeAPConversionsData={adTypeAPConversionsData}
              adTypeSpendSeries={adTypeSpendSeries}
              adTypeBuyingPriceData={adTypeBuyingPriceData}
              currencyCode={currencyCode}
            />
          </div>
        </div>
        <ReportLinks organizationId={selectedClient} />
      </div>
    </>
  );
}

export default Dashboard;
