import classnames from "classnames";
import { ScaleBand, ScaleLinear } from "d3-scale";
import React from "react";
import { DashboardAbsoluteKPIs } from "shared/http/apiTypes/dashboardApiTypes";
import { CurrencyCode } from "shared/utilities/isoCodes";
import classes from "./Axes.module.css";
import CategoricalXAxis from "./CategoricalXAxis";
import YAxis from "./YAxis";

type Props = {
  x: ScaleBand<string>;
  y: ScaleLinear<number, number>;
  maxY: number;
  yAxisLabel?: string;
  renderYTickGuidelines?: boolean;
  kpi: DashboardAbsoluteKPIs;
  currencyCode?: CurrencyCode;
};

function CategoricalAxes({ x, y, maxY, yAxisLabel, renderYTickGuidelines, currencyCode }: Props) {
  return (
    <g>
      <line
        key={`yAxisLine`}
        x1={0}
        y1={y(maxY)}
        x2={0}
        y2={y(0)}
        className={classnames(classes.line, classes.axisLine)}
      />
      <line
        key={`xAxisLine`}
        x1={0}
        y1={y(0)}
        x2={x.range()[1]}
        y2={y(0)}
        className={classnames(classes.line, classes.axisLine)}
      />
      <YAxis y={y} maxSvgX={x.range()[1]!} renderGuideLines={renderYTickGuidelines} currencyCode={currencyCode} />
      <g transform={`translate(0,${y(0)!})`}>
        <CategoricalXAxis x={x} />
      </g>
      <g transform={`translate(${0 - 80},${y(maxY / 3)}) rotate(-90)`}>
        <text x={0} y={0}>
          {yAxisLabel}
        </text>
      </g>
    </g>
  );
}

export default React.memo(CategoricalAxes);
