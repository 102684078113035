import classNames from "classnames";
import classes from "./Dashboard.module.css";

export function NoDataBanner() {
  return (
    <div className={classNames(classes.noDataNotification, classes.overviewSectionLoading)}>
      There was no advertising activity in the selected period.
    </div>
  );
}
