import { max, min, sum } from "d3-array";
import { ScaleLinear, ScaleTime, scaleLinear, scaleTime } from "d3-scale";
import { area, curveCardinal, stack } from "d3-shape";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { CurrencyCode } from "shared/utilities/isoCodes";
import { formatIsoDate } from "../common/formatDate";
import formatNumber from "../common/formatNumber";
import mouseEventToDomPoint from "../common/mouseEventToDomPoint";
import { ShowTooltipEvent, useDebouncedEventTracking } from "../common/tracking";
import Tooltip from "./Tooltip";
import Axes from "./charts/Axes";
import chartClasses from "./charts/chart.module.css";
import { height, margin, stackedAreaHeight, stackedAreaWidth, width } from "./charts/dimensions";
import { nearestItemTo, shortenedCampaignName } from "./charts/helpers";
import { CurrentItem, Series, TooltipLocation } from "./charts/types";

type ChartItem = {
  x: number;
  y: number;
  y1: number;
};

export type StackedAreaChartProps = {
  series: Series[];
  graphName: string;
  organizationId: string;
  formatter?: (v: number) => string;
  currencyCode?: CurrencyCode;
};

type SelectedSeries = TooltipLocation & {
  name: string;
};

export function StackedAreaChart({
  series,
  graphName,
  organizationId,
  currencyCode,
  formatter: valueFormatter = formatNumber,
}: React.PropsWithChildren<StackedAreaChartProps>) {
  const [tooltip, setTooltip] = React.useState(null as SelectedSeries | null);
  const [chartWidth, setChartWidth] = React.useState<number>(stackedAreaWidth);
  const [chartHeight, setChartHeight] = React.useState<number>(stackedAreaHeight);

  const trackEvent = useDebouncedEventTracking(5000);

  const showToolTip = useCallback(
    (series: SelectedSeries | null) => {
      setTooltip(series);
      trackEvent(new ShowTooltipEvent({ "Organization Id": organizationId, "Graph Name": graphName }));
    },
    [trackEvent, organizationId, graphName],
  );

  useEffect(() => {
    const windowWidth = document.body.clientWidth;
    if (windowWidth < 400) {
      setChartWidth(width);
      setChartHeight(height);
    } else {
      setChartWidth(stackedAreaWidth);
      setChartHeight(stackedAreaHeight);
    }
  }, []);

  const { x, y, minX, maxX, maxY } = useMemo(() => {
    const allValues = ([] as CurrentItem<Date>[]).concat(...series.map(s => s.values));
    const maxY = maxYSumForOneDay(series);
    const minX = min(allValues.map(v => v.x))!;
    const maxX = max(allValues.map(v => v.x))!;
    const x = scaleTime().domain([minX, maxX]).range([0, chartWidth]);

    const y = scaleLinear().domain([0, maxY]).range([chartHeight, 0]);

    return { minX, maxY, maxX, x, y };
  }, [series, chartWidth, chartHeight]);

  const svgRef = useRef<SVGSVGElement>(null);

  const selectedSeries = series.find(s => s.label === tooltip?.name) || null;
  const itemClosestToMouse =
    tooltip && selectedSeries && nearestItemTo(x.invert(tooltip.svgX - margin.left), selectedSeries.values);

  return (
    <div>
      <svg
        viewBox={`0 0 ${chartWidth + margin.left + margin.right} ${chartHeight + margin.bottom + margin.top}`}
        ref={svgRef}
        className={chartClasses.svg}
      >
        <g transform={`translate(${margin.left},${margin.top})`} width={chartWidth + margin.right} height={chartHeight}>
          <g className="series">
            <SeriesGraphs
              {...{
                series,
                x,
                y,
                svgRef,
                displayTooltip: showToolTip,
              }}
            />
          </g>
          <Axes
            {...{
              x,
              y,
              minX,
              maxX,
              max,
              maxY,
              currencyCode,
              formatter: valueFormatter,
            }}
          />
          {itemClosestToMouse && (
            <line
              style={{ transform: `translate(${x(itemClosestToMouse.x)}px)` }}
              className={chartClasses.tooltipHighlightLine}
              x1={0}
              x2={0}
              y1={y(0)!}
              y2={y(maxY)}
            />
          )}
        </g>
      </svg>
      {tooltip && (
        <Tooltip
          side={tooltip.svgX > stackedAreaWidth / 2 ? "left" : "right"}
          pageX={tooltip.mouseX}
          pageY={tooltip.mouseY}
        >
          <h3>{shortenedCampaignName(tooltip.name)}</h3>
          <p>
            {itemClosestToMouse && formatIsoDate(itemClosestToMouse.x) + ": "}
            {valueFormatter(itemClosestToMouse?.y || 0)}
          </p>
        </Tooltip>
      )}
    </div>
  );
}

const SeriesGraphs = React.memo(
  ({
    x,
    y,
    svgRef,
    series,
    displayTooltip,
  }: React.PropsWithChildren<{
    series: Series[];
    svgRef: React.RefObject<SVGSVGElement>;
    x: ScaleTime<number, number>;
    y: ScaleLinear<number, number>;
    displayTooltip: (series: SelectedSeries | null) => void;
  }>) => {
    const byUnixDate: {
      [ts: string]: { [option: string]: number };
    } = {};
    const labelToColor: { [label: string]: string } = {};
    series.forEach(s => {
      labelToColor[s.label] = s.color;
      s.values.forEach(v => {
        const ts = v.x.valueOf().toString();
        if (!byUnixDate[ts]) {
          byUnixDate[ts] = {};
        }

        byUnixDate[ts][s.label] = v.y;
      });
    });

    const objectPerDate: ({ [option: string]: number } & {
      ts: number;
    })[] = Object.entries(byUnixDate)
      .map(([ts, optionToRatio]) => ({
        ts: Number(ts),
        ...optionToRatio,
      }))
      .sort((a, b) => a.ts - b.ts);

    const stackGenerator = stack().keys(series.map(s => s.label));
    const stackedData = stackGenerator(objectPerDate);
    return (
      <>
        <pattern id="diagonal-stripe-1" patternUnits="userSpaceOnUse" width="10" height="10">
          <image
            xlinkHref="data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMCcgaGVpZ2h0PScxMCc+CiAgPHJlY3Qgd2lkdGg9JzEwJyBoZWlnaHQ9JzEwJyBmaWxsPSIjMDAwIiBmaWxsLW9wYWNpdHk9IjAiLz4KICA8cGF0aCBkPSdNLTEsMSBsMiwtMgogICAgICAgICAgIE0wLDEwIGwxMCwtMTAKICAgICAgICAgICBNOSwxMSBsMiwtMicgc3Ryb2tlPSdibGFjaycgc3Ryb2tlLXdpZHRoPScxJy8+Cjwvc3ZnPgo="
            x="0"
            y="0"
            width="10"
            height="10"
          ></image>
        </pattern>
        {stackedData.map(s => {
          const transformedValues = s
            .map(p => {
              const date = new Date(p.data.ts);

              const xtoDraw = x(date)!;
              const y0ToDraw = y(p[0])!;
              const y1ToDraw = y(p[1])!;

              return {
                x: xtoDraw,
                y: y0ToDraw,
                y1: y1ToDraw,
              };
            })
            .filter(d => d.x !== undefined && d.y !== undefined);

          const showTooltip = (event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
            const svg = svgRef.current;
            if (!svg) {
              return;
            }
            const { x, y } = mouseEventToDomPoint(event, svg);
            displayTooltip({
              name: s.key,
              svgX: x,
              svgY: y,
              mouseX: event.pageX,
              mouseY: event.pageY,
            });
          };
          const hideTooltip = () => {
            displayTooltip(null);
          };

          const seriesColor = labelToColor[s.key];
          const fill = seriesColor;
          const stroke = "black";

          return path(transformedValues, {
            key: s.key,
            style: { fill, stroke },
            onMouseMove: showTooltip,
            onClick: showTooltip,
            onMouseLeave: hideTooltip,
            className: chartClasses.stackArea,
          });
        })}
      </>
    );
  },
);

function path(values: ChartItem[], pathProps: React.SVGProps<SVGPathElement>) {
  const a = area<ChartItem>()
    .x(d => d.x)
    .y0(d => d.y)
    .y1(d => d.y1)
    .curve(curveCardinal.tension(0.6));

  return <path d={a(values)!} {...pathProps} />;
}

function maxYSumForOneDay(series: Series[]) {
  const uniqueDayTimestamps = new Set<number>();
  series.forEach(s => s.values.forEach(v => uniqueDayTimestamps.add(v.x.valueOf())));

  let maxSum = 0;
  uniqueDayTimestamps.forEach(ts => {
    const valuePerSeries = series.map(s => s.values.find(v => v.x.valueOf() === ts)?.y || 0);
    const sumForTs = sum(valuePerSeries);
    if (maxSum < sumForTs) {
      maxSum = sumForTs;
    }
  });

  return maxSum;
}
