import { max, min } from "d3-array";
import { ScaleLinear, ScaleTime, scaleLinear, scaleTime } from "d3-scale";
import { curveCardinal, line } from "d3-shape";
import React, { useCallback, useMemo, useRef } from "react";
import mouseEventToDomPoint from "../common/mouseEventToDomPoint";
import Tooltip from "./Tooltip";
import chartClasses from "./charts/chart.module.css";

import { DashboardAbsoluteKPIs } from "shared/http/apiTypes/dashboardApiTypes";
import { CurrencyCode } from "shared/utilities/isoCodes";
import formatNumber from "../common/formatNumber";
import { ShowTooltipEvent, useDebouncedEventTracking } from "../common/tracking";
import Axes from "./charts/Axes";
import { height, margin, width } from "./charts/dimensions";
import { nearestItemTo } from "./charts/helpers";
import { defaultLineChartTooltipRenderer } from "./charts/tooltipRenderers";
import { CurrentItem, Series, TimeseriesTooltipRenderer, TooltipItems, TooltipLocation } from "./charts/types";

export type LineChartProps = {
  series: Series[];
  maxValueGuideLine?: number;
  yAxisLabel?: string;
  tooltipRenderer?: TimeseriesTooltipRenderer;
  kpi?: DashboardAbsoluteKPIs;
  currencyCode?: CurrencyCode;
  graphName: string;
  organizationId: string;
};

function LineChart({
  series,
  maxValueGuideLine,
  yAxisLabel,
  graphName,
  organizationId,
  tooltipRenderer = defaultLineChartTooltipRenderer,
  kpi,
  currencyCode,
}: LineChartProps) {
  const { maxY, minX, maxX, x, y } = useMemo(() => {
    let maxY = max(allValues(series, "y"))!;
    if (maxValueGuideLine && maxValueGuideLine > maxY) {
      maxY = maxValueGuideLine;
    }

    const minX = min(allValues(series, "x"))!;
    const maxX = max(allValues(series, "x"))!;

    const x = scaleTime().domain([minX, maxX]).range([0, width]);
    const y = scaleLinear().domain([0, maxY]).range([height, 0]);

    return { maxY, minX, maxX, x, y };
  }, [maxValueGuideLine, series]);
  const svgRef = useRef<SVGSVGElement>(null);
  const [tooltip, setTooltip] = React.useState(null as TooltipLocation | null);
  const trackEvent = useDebouncedEventTracking(5000);

  const showTooltip = useCallback(
    (event: React.MouseEvent<SVGGElement, MouseEvent>) => {
      const svg = svgRef.current;
      if (!svg) {
        return;
      }
      const { x, y } = mouseEventToDomPoint(event, svg);
      setTooltip({
        svgX: x,
        svgY: y,
        mouseX: event.pageX,
        mouseY: event.pageY,
      });
      trackEvent(new ShowTooltipEvent({ "Organization Id": organizationId, "Graph Name": graphName }));
    },
    [organizationId, graphName, trackEvent],
  );

  const hideTooltip = useCallback(() => {
    setTooltip(null);
  }, []);

  const closestTimeToMouse =
    tooltip && series.length > 0 && nearestItemTo(x.invert(tooltip.svgX - margin.left), series[0].values).x;

  const closestValueToMousePerSeries =
    tooltip &&
    closestTimeToMouse &&
    series.length > 0 &&
    series.reduce<TooltipItems>((acc, current) => {
      const closestItem = current.values.find(value => value.x.valueOf() === closestTimeToMouse.valueOf());
      if (!closestItem) {
        return acc;
      }

      return {
        ...acc,
        [current.label]: {
          value: closestItem.y,
          color: current.color,
        },
      };
    }, {});

  return (
    <div>
      {tooltip && closestTimeToMouse && (
        <Tooltip side={tooltip.svgX > width / 2 ? "left" : "right"} pageX={tooltip.mouseX} pageY={tooltip.mouseY}>
          {closestValueToMousePerSeries &&
            tooltipRenderer(closestTimeToMouse, closestValueToMousePerSeries, formatNumber, kpi, currencyCode)}
        </Tooltip>
      )}
      <svg
        className={chartClasses.svg}
        viewBox={`0 0 ${width + margin.left + margin.right} ${height + margin.bottom + margin.top}`}
        ref={svgRef}
        onMouseMove={showTooltip}
        onClick={showTooltip}
        onMouseLeave={hideTooltip}
      >
        <g transform={`translate(${margin.left},${margin.top})`}>
          {series.length > 0 && (
            <Axes
              {...{
                x,
                y,
                minX,
                maxX,
                max,
                maxY,
                yAxisLabel,
                renderYTickGuidelines: true,
                kpi,
                currencyCode,
              }}
            />
          )}
          {series.map(s => (
            <Graph key={s.label} x={x} y={y} series={s} />
          ))}

          {closestTimeToMouse && (
            <line
              style={{ transform: `translate(${x(closestTimeToMouse)}px)` }}
              className={chartClasses.tooltipHighlightLine}
              x1={0}
              x2={0}
              y1={y(0)!}
              y2={y(maxY)}
            />
          )}

          {closestValueToMousePerSeries &&
            Object.entries(closestValueToMousePerSeries).map(([title, { value }]) => (
              <line
                key={title}
                style={{
                  transform: `translate(0, ${y(value)}px)`,
                }}
                className={chartClasses.tooltipHighlightLine}
                x1={0}
                x2={x(maxX)}
                y1={0}
                y2={0}
              />
            ))}
        </g>
      </svg>
    </div>
  );
}

const Graph = React.memo(
  ({
    x,
    y,
    series,
    pathProps,
  }: {
    series: Series;
    x: ScaleTime<number, number>;
    y: ScaleLinear<number, number>;
    pathProps?: React.SVGProps<SVGPathElement>;
  }) => {
    const a = line<{ x: number; y: number }>()
      .x(d => d.x)
      .y(d => d.y)
      .curve(curveCardinal.tension(0.5));

    const values = series.values.map(i => ({
      x: x(i.x)!,
      y: y(i.y)!,
    }));

    return <path className={chartClasses.graph} stroke={series.color} d={a(values)!} {...pathProps} />;
  },
);

function allValues<Key extends keyof CurrentItem<Date>>(series: Series[], k: Key): CurrentItem<Date>[Key][] {
  return series.flatMap(s => s.values.map(i => i[k]));
}

export default React.memo(LineChart);
