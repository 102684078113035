import { Icon } from "@mui/material";
import classNames from "classnames";
import DataTable, { TableColumn, createTheme } from "react-data-table-component";
import { Microdollar } from "shared/http/apiTypes";
import { DashboardAdType } from "shared/http/apiTypes/dashboardApiTypes";
import { i18n } from "shared/utilities/I18nContext";
import { CurrencyCode } from "shared/utilities/isoCodes";
import { microValueToDollarValue } from "shared/utilities/numbers";
import formatNumber, { formatPercent } from "../common/formatNumber";
import classes from "./DashboardDataTable.module.css";
import chartClasses from "./charts/chart.module.css";

export const adTypeColor = {
  image: "#d2c8ff",
  native: "#1d1d1f",
  video: "#bfbda7",
  template: "#edff53",
};

type DataTableProps = {
  data: { [key: string]: unknown }[];
  theme?: string;
  columns?: TableColumn<{ [key: string]: unknown }>[];
};

createTheme("light", {
  background: "#faf8f6",
});

export type TableRow = {
  buying_price: Microdollar;
  currencyCode: CurrencyCode;
  impressions: number;
  clicks: number;
  ctr: number;
  ap_conversions: number;
  prev_buying_price: Microdollar;
  prev_impressions?: number;
  prev_clicks?: number;
  prev_ctr?: number;
  prev_ap_conversions?: number;
  color: string;
  campaign?: string;
  ad_type: DashboardAdType;
};

const MAX_NUMBER_COLUMN_WIDTH = "220px";

const campaignTableColumns = [
  {
    name: "",
    cell: (row: TableRow) => {
      if (!row.campaign) return "";
      return (
        <div className={classes.campaignLegend}>
          <div className={classes.icon} style={{ backgroundColor: row.color }}></div>
          {row.campaign}
        </div>
      );
    },
    sortable: true,
    minWidth: "380px",
  },
  {
    name: "Ad Spend",
    cell: (row: TableRow) => {
      const mainNumberFormatted = i18n.formatMoney(
        { value: microValueToDollarValue(row.buying_price), currencyCode: row.currencyCode },
        "short",
      );
      if (row.prev_buying_price === 0 || !row.prev_buying_price) return mainNumberFormatted;

      const delta = (row.buying_price - row.prev_buying_price) / row.prev_buying_price;

      return (
        <div className={classes.tableCell}>
          {mainNumberFormatted}{" "}
          <div
            className={classNames(classes.delta, delta > 0 ? chartClasses.positiveChange : chartClasses.negativeChange)}
          >
            <Icon>{delta > 0 ? "arrow_upward" : "arrow_downward"}</Icon>
            {formatPercent(delta, true)}
          </div>
        </div>
      );
    },
    selector: (row: TableRow) => row.buying_price,
    format: (row: TableRow) => i18n.formatNumber(Math.round(microValueToDollarValue(row.buying_price))),
    sortable: true,
    minWidth: "160px",
    maxWidth: MAX_NUMBER_COLUMN_WIDTH,
    right: true,
  },
  {
    name: "Impressions",
    cell: (row: TableRow) => {
      const mainNumberFormatted = formatNumber(row.impressions);
      if (row.prev_impressions === 0 || !row.prev_impressions) return mainNumberFormatted;

      const delta = (row.impressions - row.prev_impressions) / row.prev_impressions;

      return (
        <div className={classes.tableCell}>
          {mainNumberFormatted}{" "}
          <div
            className={classNames(classes.delta, delta > 0 ? chartClasses.positiveChange : chartClasses.negativeChange)}
          >
            <Icon>{delta > 0 ? "arrow_upward" : "arrow_downward"}</Icon>
            {formatPercent(delta, true)}
          </div>
        </div>
      );
    },
    selector: (row: TableRow) => row.impressions,
    format: (row: TableRow) => formatNumber(row.impressions),
    sortable: true,
    maxWidth: MAX_NUMBER_COLUMN_WIDTH,
    right: true,
  },
  {
    name: "Clicks",
    cell: (row: TableRow) => {
      const mainNumberFormatted = formatNumber(row.clicks);
      if (row.prev_clicks === 0 || !row.prev_clicks) return mainNumberFormatted;

      const delta = (row.clicks - row.prev_clicks) / row.prev_clicks;

      return (
        <div className={classes.tableCell}>
          {mainNumberFormatted}{" "}
          <div
            className={classNames(classes.delta, delta > 0 ? chartClasses.positiveChange : chartClasses.negativeChange)}
          >
            <Icon>{delta > 0 ? "arrow_upward" : "arrow_downward"}</Icon>
            {formatPercent(delta, true)}
          </div>
        </div>
      );
    },
    selector: (row: TableRow) => row.clicks,
    format: (row: TableRow) => formatNumber(row.clicks),
    sortable: true,
    maxWidth: MAX_NUMBER_COLUMN_WIDTH,
    right: true,
  },
  {
    name: "CTR",
    cell: (row: TableRow) => {
      const mainNumberFormatted = row.ctr.toFixed(2);
      if (row.prev_ctr === 0 || !row.prev_ctr) return mainNumberFormatted;

      const delta = (row.ctr - row.prev_ctr) / row.prev_ctr;

      return (
        <div className={classes.tableCell}>
          {`${mainNumberFormatted}%`}{" "}
          <div
            className={classNames(classes.delta, delta > 0 ? chartClasses.positiveChange : chartClasses.negativeChange)}
          >
            <Icon>{delta > 0 ? "arrow_upward" : "arrow_downward"}</Icon>
            {formatPercent(delta, true)}
          </div>
        </div>
      );
    },
    selector: (row: TableRow) => row.ctr,
    format: (row: TableRow) => `${row.ctr.toFixed(2)}%`,
    sortable: true,
    maxWidth: MAX_NUMBER_COLUMN_WIDTH,
    right: true,
  },
  {
    name: "Ap Conversions",
    cell: (row: TableRow) => {
      const mainNumberFormatted = formatNumber(row.ap_conversions);
      if (row.prev_ap_conversions === 0 || !row.prev_ap_conversions) return mainNumberFormatted;

      const delta = (row.ap_conversions - row.prev_ap_conversions) / row.prev_ap_conversions;

      return (
        <div className={classes.tableCell}>
          {mainNumberFormatted}{" "}
          <div
            className={classNames(classes.delta, delta > 0 ? chartClasses.positiveChange : chartClasses.negativeChange)}
          >
            <Icon>{delta > 0 ? "arrow_upward" : "arrow_downward"}</Icon>
            {formatPercent(delta, true)}
          </div>
        </div>
      );
    },
    selector: (row: TableRow) => row.ap_conversions,
    format: (row: TableRow) => formatNumber(row.ap_conversions),
    sortable: true,
    maxWidth: MAX_NUMBER_COLUMN_WIDTH,
    right: true,
  },
];

function DashboardDataTable({ data, theme, columns }: DataTableProps) {
  let tableColumns: TableColumn<{ [key: string]: unknown }>[];
  if (columns) {
    tableColumns = columns;
  } else {
    tableColumns = campaignTableColumns;
  }

  return <DataTable columns={tableColumns} data={data} theme={theme} />;
}

export default DashboardDataTable;
