import { Icon } from "@mui/material";
import classNames from "classnames";
import { useContext } from "react";
import { I18nContext, TI18n } from "shared/utilities/I18nContext";
import { CurrencyCode } from "shared/utilities/isoCodes";
import formatNumber from "../common/formatNumber";
import classes from "./KPITile.module.css";
import chartClasses from "./charts/chart.module.css";

type Props = {
  kpi: OverviewKPIs;
  currentPeriodNumber: number;
  previousPeriodNumber: number;
  currencyCode: CurrencyCode;
};

export const executiveSummaryLabels = {
  impressions: "Impressions",
  clicks: "Clicks",
  cpc: "CPC",
  ctr: "CTR",
  buying_price: "Cost",
  ap_conversions: "AP Conversions",
  ap_cpa: "AP CPA",
};

export type OverviewKPIs = keyof typeof executiveSummaryLabels;

const formatKPI = (kpi: OverviewKPIs, numberToFormat: number | undefined, currencyCode: CurrencyCode, i18n: TI18n) => {
  if (!numberToFormat) return;

  switch (kpi) {
    case "ctr":
    case "clicks":
    case "impressions":
      return formatNumber(numberToFormat);
    case "cpc":
      return i18n.formatMoney({ currencyCode, value: numberToFormat });
    case "ap_cpa":
      return i18n.formatMoney({ currencyCode, value: numberToFormat }, "default", 2);
    case "buying_price":
      return i18n.formatMoney({ currencyCode, value: numberToFormat }, "short", 0);
    default:
      return i18n.formatNumber(Math.round(numberToFormat));
  }
};

function KPITile({ currentPeriodNumber, previousPeriodNumber, kpi, currencyCode }: Props) {
  const i18n = useContext(I18nContext);
  const absoluteChange = currentPeriodNumber - previousPeriodNumber;
  const percentageChange = parseFloat(((absoluteChange / previousPeriodNumber) * 100).toFixed(2));

  const formattedCurrentPeriodNumber = formatKPI(kpi, currentPeriodNumber, currencyCode, i18n);
  const formattedPreviousPeriodNumber = formatKPI(kpi, previousPeriodNumber, currencyCode, i18n);

  const formattedAbsoluteChange = absoluteChange && formatKPI(kpi, Math.abs(absoluteChange), currencyCode, i18n);

  let positiveChange: boolean;
  if (kpi === "ap_cpa" || kpi === "cpc") {
    positiveChange = absoluteChange < 0;
  } else {
    positiveChange = absoluteChange > 0;
  }

  return (
    <div className={classes.card}>
      <div className={classes.cardContent}>
        <div className={classNames(classes.KPIname, classes.cardElement)}>{executiveSummaryLabels[kpi]}</div>
        <div className={classNames(classes.currentPeriodNumber, classes.cardElement)}>
          {formattedCurrentPeriodNumber}
        </div>
        {previousPeriodNumber && (
          <div className={classNames(classes.previousPeriodNumber, classes.cardElement)}>
            {formattedPreviousPeriodNumber}
          </div>
        )}
        {percentageChange ? (
          <div
            className={classNames(
              classes.percentageChange,
              classes.cardElement,
              positiveChange ? chartClasses.positiveChange : chartClasses.negativeChange,
            )}
          >
            <Icon>{percentageChange > 0 ? "arrow_upward" : "arrow_downward"}</Icon>
            {`${formattedAbsoluteChange} (${percentageChange}%)`}
          </div>
        ) : (
          <div
            className={classNames(
              classes.percentageChange,
              classes.cardElement,
              positiveChange ? chartClasses.positiveChange : chartClasses.negativeChange,
            )}
          >
            <Icon>{percentageChange > 0 ? "arrow_upward" : "arrow_downward"}</Icon>
            {`${formattedAbsoluteChange}`}
            {" (0%)"}
          </div>
        )}
      </div>
    </div>
  );
}

export default KPITile;
