import { Microdollar, MoneyInCents } from "shared/http/apiTypes";

const MICRODOLLARS_PER_DOLLAR = 10 ** 6;

const CENTS_PER_DOLLAR = 100;

const MICRODOLLARS_PER_CENT = MICRODOLLARS_PER_DOLLAR / CENTS_PER_DOLLAR;

export function dollarValueToMicroValue(dollarValue: number): Microdollar {
  return Math.round(dollarValue * MICRODOLLARS_PER_DOLLAR);
}

export function microValueToDollarValue(microValue: Microdollar): number {
  return microValue / MICRODOLLARS_PER_DOLLAR;
}

export function roundMicroDollarValueToCents(microValue: Microdollar): number {
  return Math.round(microValue / MICRODOLLARS_PER_CENT) * MICRODOLLARS_PER_CENT;
}

export function dollarValueToCentValue(dollarValue: number): MoneyInCents {
  return Math.round(dollarValue * CENTS_PER_DOLLAR);
}

export function centValueToDollarValue(centValue: MoneyInCents): number {
  return centValue / CENTS_PER_DOLLAR;
}

export function decimalToPercentageValue(decimal: number): number {
  return Math.round(decimal * 100 * 100) / 100;
}

export function percentageToDecimalValue(percentage: number): number {
  return percentage / 100;
}
