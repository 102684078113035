import classNames from "classnames";
import React from "react";
import classes from "./Legend.module.css";
import LegendIcon from "./LegendIcon";
import { CategoricalData, Series } from "./types";

type LegendOrientation = "horizontal" | "vertical";

export type LegendProps = {
  series: Series[] | CategoricalData[];
  orientation?: LegendOrientation;
};

function Legend({ series, orientation }: LegendProps) {
  return (
    <div
      className={classNames(classes.container, orientation === "horizontal" ? classes.horizontal : classes.vertical)}
    >
      {series.map(series => (
        <div key={series.label} className={classes.legendGroup}>
          <LegendIcon color={series.color} />
          <h5 className={classes.label}>{series.label}</h5>
        </div>
      ))}
    </div>
  );
}

export default React.memo(Legend);
