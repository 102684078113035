export const width = 1200;
export const height = 400;

export const stackedAreaWidth = 600;
export const stackedAreaHeight = 300;

export const margin = {
  top: 20,
  right: 60,
  left: 100,
  bottom: 30,
};

export const tickLength = 10;
