import { SvgIcon } from "@mui/material";
import IconProps from "./IconProps";

function ErrorIcon({ fill = "#3a3a3b", className }: IconProps) {
  return (
    <SvgIcon viewBox="0 0 109 110" classes={{ root: className }}>
      <defs>
        <style>{`.prefix__cls-1{fill:${fill}}`}</style>
      </defs>
      <title>{"Error"}</title>
      <path
        d="M49.5669 69.0075H58.5669V78.0908H49.5669V69.0075ZM49.5669 32.6742H58.5669V59.9242H49.5669V32.6742ZM54.0219 9.96582C29.1819 9.96582 9.06689 30.3125 9.06689 55.3825C9.06689 80.4525 29.1819 100.799 54.0219 100.799C78.9069 100.799 99.0669 80.4525 99.0669 55.3825C99.0669 30.3125 78.9069 9.96582 54.0219 9.96582ZM54.0669 91.7158C34.1769 91.7158 18.0669 75.4567 18.0669 55.3825C18.0669 35.3083 34.1769 19.0492 54.0669 19.0492C73.9569 19.0492 90.0669 35.3083 90.0669 55.3825C90.0669 75.4567 73.9569 91.7158 54.0669 91.7158Z"
        fill="#F47979"
      />
    </SvgIcon>
  );
}

export default ErrorIcon;
