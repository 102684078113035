import { useCallback, useContext } from "react";
import { useErrorHandler } from "react-error-boundary";
import { BatchedCollectionGetRequest, RemergeApiRequest } from "shared/http/RemergeApi";
import { ICollectionDocument, PersistedResourceObject } from "shared/http/jsonApi";
import useSWR, { SWRConfiguration, SWRResponse, preload } from "swr";
import { RemergeAPIContext } from "./remergeApiContext";

type RemergeApiSwrOptions<T> = {
  /**
   * Opt in to manual error handling. If true, errors will not be automatically thrown to the nearest error boundary. You will need to manually check the error property on the SWRResponse.
   */
  manualErrorHandling?: boolean;
  swrConfig?: SWRConfiguration<T, Error, never>;
};

type Falsy = null | undefined | false | "";

/**
 * Returns a complete API response via useSWR().
 */
export function useRemergeSwrResponse<T>(
  requestOrFn: Falsy | RemergeApiRequest<T> | (() => RemergeApiRequest<T>),
  options?: RemergeApiSwrOptions<T>,
): SWRResponse<T> {
  const remergeApi = useContext(RemergeAPIContext);
  const result = useSWR<T>(requestOrFn, remergeApi.fetchRequest, options?.swrConfig);

  if (result.error && !options?.manualErrorHandling) {
    throw result.error;
  }

  return result;
}

export type RemergeSwrDataResponse<T extends { data: unknown }> = Omit<SWRResponse<T["data"]>, "mutate">;

/**
 * Returns only the `data` portion of an API response.
 */
export function useRemergeSwrData<T extends { data: unknown }>(
  requestOrFn: Falsy | RemergeApiRequest<T> | (() => RemergeApiRequest<T>),
  options?: RemergeApiSwrOptions<T>,
): RemergeSwrDataResponse<T> {
  const swrResponse = useRemergeSwrResponse(requestOrFn, options);

  // Avoid reading SWRResponse property getters unnecessarily
  // See https://swr.vercel.app/docs/advanced/performance#dependency-collection
  return {
    get data() {
      return swrResponse.data?.data;
    },
    get error() {
      return swrResponse.error;
    },
    get isValidating() {
      return swrResponse.isValidating;
    },
    get isLoading() {
      return swrResponse.isLoading;
    },
  };
}

/**
 * Returns the response of a batched GET collection request, e.g. adAssetsIndex().
 */
export function useBatchedGetSWRResponse<Resource extends PersistedResourceObject<any, any>>(
  requestOrFn: Falsy | BatchedCollectionGetRequest<Resource> | (() => BatchedCollectionGetRequest<Resource>),
  options?: RemergeApiSwrOptions<ICollectionDocument<Resource>>,
): SWRResponse<ICollectionDocument<Resource>> {
  const remergeApi = useContext(RemergeAPIContext);
  const result = useSWR<ICollectionDocument<Resource>>(
    requestOrFn,
    remergeApi.fetchBatchedCollectionGetRequest,
    options?.swrConfig,
  );

  const throwErrors = !options?.manualErrorHandling;
  useErrorHandler(throwErrors && result.error);

  return result;
}

/**
 * Exposes prefetch functionality for use inside a component,
 * since the RemergeAPIContext needs to be available
 */
export function useRemergePrefetch<T>() {
  const remergeApi = useContext(RemergeAPIContext);
  const prefetch = useCallback(
    (requestOrFn: Falsy | RemergeApiRequest<T> | (() => RemergeApiRequest<T>)) => {
      preload(requestOrFn, remergeApi.fetchRequest);
    },
    [remergeApi],
  );

  return prefetch;
}
