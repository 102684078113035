import { DateRange } from "shared/components/DateRangeSelect";

export function formatDateRangeForAPICall(dateRange: DateRange) {
  if (!dateRange.startDate || !dateRange.endDate) {
    return { startDate: undefined, endDate: undefined };
  }

  const [startDate, endDate] = [dateRange.startDate, dateRange.endDate]
    .sort((a, b) => a.getTime() - b.getTime())
    .map(formatIsoDate);

  return {
    startDate,
    endDate,
  };
}

// YYYY-MM-DD
export function formatIsoDate(date: Date) {
  return date.toISOString().substring(0, 10);
}
