import { TableRow } from "react-data-table-component";
import { DashboardAdType } from "shared/http/apiTypes/dashboardApiTypes";
import { i18n } from "shared/utilities/I18nContext";
import { CurrencyCode } from "shared/utilities/isoCodes";
import { microValueToDollarValue } from "shared/utilities/numbers";
import { adTypeColor } from "../components/DashboardDataTable";
import classes from "./../components/DashboardDataTable.module.css";
import formatNumber from "./formatNumber";
import { CreativeTypeChartKPI } from "./transformData";

type Primitive = string | number | boolean | bigint;

export const calculateAdTypeDataTableColumns = (
  dataEntry: { [key: string]: number | string | DashboardAdType },
  kpi: CreativeTypeChartKPI,
) => {
  return Object.keys(dataEntry)
    .filter(entry => entry !== "currencyCode")
    .map(key => {
      const adType = key === "ad_type";
      const columnData = {
        name: adType ? "" : key,
        sortable: true,
        selector: (row: TableRow) => row[key] as Primitive,
        ...(!adType && {
          format: (row: TableRow) => {
            if (!row[key]) {
              return kpi === "ap_conversions" ? 0 : "$0";
            }
            return kpi === "ap_conversions"
              ? formatNumber(row[key] as number, true)
              : i18n.formatMoney(
                  {
                    value: microValueToDollarValue(row[key] as number),
                    currencyCode: row.currencyCode as CurrencyCode,
                  },
                  "short",
                );
          },
        }),
        ...(adType && {
          cell: (row: TableRow) => {
            const value = row[key] as DashboardAdType;
            const color = adTypeColor[value];

            return (
              <>
                <div className={classes.icon} style={{ backgroundColor: color }}></div>
                <div style={{ marginLeft: "1rem" }}>{value}</div>
              </>
            );
          },
        }),
      };
      return columnData;
    });
};
