import classNames from "classnames";
import { ScaleBand } from "d3-scale";
import React from "react";
import axesClasses from "./Axes.module.css";
import classes from "./CategoricalXAxis.module.css";
import { tickLength } from "./dimensions";

function CategoricalXAxis({ x }: { x: ScaleBand<string> }) {
  const tickValues = x.domain();
  const halfBandWidth = x.bandwidth() / 2;
  const quarterBandWidth = x.bandwidth() / 2;

  return (
    <>
      {tickValues.map(value => {
        const displayValue = value.includes("others") ? value.split(":")[0] : value;
        return (
          <g key={`tick/${value}`}>
            <line
              x1={x(value)! + halfBandWidth}
              x2={x(value)! + halfBandWidth}
              y1={0}
              y2={tickLength}
              className={axesClasses.line}
            />
            <g transform={`translate(${x(value)! + quarterBandWidth},${tickLength + 15})`}>
              <text x={0} y={0} className={classNames(axesClasses.xLabels, classes.label)}>
                {displayValue}
              </text>
            </g>
          </g>
        );
      })}
    </>
  );
}

export default React.memo(CategoricalXAxis);
