import { useCallback, useContext, useEffect, useRef } from "react";
import TrackingContext from "shared/services/TrackingContext";
import { TrackingEvent } from "shared/services/TrackingService";
import { SWRResponse } from "swr";

export function usePageLoadTracking(organizationId: string, ...resources: SWRResponse<any>[]) {
  const trackingService = useContext(TrackingContext);
  const pageLoadStartTime = useRef(new Date());
  const pageLoadTracked = useRef(false);

  useEffect(() => {
    if (pageLoadTracked.current) return;
    if (resources.some(r => !r.data)) return;

    pageLoadTracked.current = true;

    const pageLoadTime = new Date().getTime() - pageLoadStartTime.current.getTime();

    trackingService.track(
      new PageLoadEvent({ "Organization Id": organizationId, "Load Duration Millis": pageLoadTime }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, resources);
}

export function useDebouncedEventTracking<E extends TrackingEvent<any>>(debounceTimeMillis: number) {
  const trackingService = useContext(TrackingContext);
  const timeoutId = useRef<NodeJS.Timeout | undefined>();

  const trackEvent = useCallback(
    (event: E) => {
      if (timeoutId.current) clearTimeout(timeoutId.current);

      timeoutId.current = setTimeout(() => {
        trackingService.track(event);
        timeoutId.current = undefined;
      }, debounceTimeMillis);
    },
    [debounceTimeMillis, trackingService],
  );

  return trackEvent;
}

type DashboardTrackingEventData = {
  "Organization Id": string;
};

type PageLoadEventData = DashboardTrackingEventData & {
  "Load Duration Millis": number;
};

export class PageLoadEvent extends TrackingEvent<PageLoadEventData> {
  readonly type = "Page Load";
}

type PickDateRangeEventData = DashboardTrackingEventData & {
  "Date Range Start": string;
  "Date Range End": string;
};

export class PickDateRangeEvent extends TrackingEvent<PickDateRangeEventData> {
  readonly type = "Pick Date Range";
}

type ShowTooltipEventData = DashboardTrackingEventData & {
  "Graph Name": string;
};

export class ShowTooltipEvent extends TrackingEvent<ShowTooltipEventData> {
  readonly type = "Show Tooltip";
}

type ClickReportsLinkEventData = DashboardTrackingEventData & {
  "Report Name": string;
};

export class ClickReportsLinkEvent extends TrackingEvent<ClickReportsLinkEventData> {
  readonly type = "Click Reports Link";
}

type ChangeKpiEventData = DashboardTrackingEventData & {
  "KPI Name": string;
  "Graph Name": string;
};

export class ChangeKpiEvent extends TrackingEvent<ChangeKpiEventData> {
  readonly type = "Change KPI";
}
