import Button from "shared/components/Button";
import ErrorIcon from "shared/components/icons/ErrorIcon";
import classes from "./ErrorMessage.module.css";
type ErrorMessageProps = {
  errorMessage: string;
  callToAction: string;
  onCallToActionClick: () => void;
};

export default function ErrorMessage(props: ErrorMessageProps) {
  return (
    <div className={classes.container}>
      <ErrorIcon className={classes.icon} />
      <h2 className={classes.text}>{props.errorMessage}</h2>
      <Button onClick={props.onCallToActionClick} variant="contained">
        {props.callToAction}
      </Button>
    </div>
  );
}
