import { AD_TYPE_VALUES, AdType } from "shared/http/apiTypes/ad";
import {
  AdTypeBreakdownAttributes,
  CampaignBreakdownAttributes,
  CombinedDataAttributes,
  DashboardAbsoluteKPIs,
  DruidEventData,
  DruidOverviewResponse,
  DruidResponse,
  ExecutiveSummaryData,
  InventoryBreakdownAttributes,
  OverviewAttributes,
} from "shared/http/apiTypes/dashboardApiTypes";
import { CurrencyCode } from "shared/utilities/isoCodes";
import { microValueToDollarValue } from "shared/utilities/numbers";
import { adTypeColor } from "../components/DashboardDataTable";
import { CurrentItem, Series } from "../components/charts/types";

const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const DRUID_METRICS = ["buying_price", "clicks", "ap_conversions", "impressions"] as const;

const KPIS = [...DRUID_METRICS, "ctr"] as const;

const LINE_COLORS = [
  "#D0C8FB",
  "#333333",
  "#BFBDA9",
  "#F0FF71",
  "#58655B",
  "#E1E4EE",
  "#7B7794",
  "#A8A7A6",
  "#737266",
  "#909944",
  "#F2F0D6",
  "#989AA1",
] as const;

export type GroupedTimeSeries = { [key: string | AdType]: { [key in DashboardAbsoluteKPIs]: CurrentItem<Date>[] } };

export type CategoricalDataItem = { x: string; y: number };

type TransformedInventoryData = { [key in DashboardAbsoluteKPIs]: CategoricalDataItem[] };

export type DataTableTimeSeries = { [key: string]: number | string };

export type CreativeTypeChartKPI = "ap_conversions" | "buying_price";

const formatKPI = (
  kpi: DashboardAbsoluteKPIs,
  event: CampaignBreakdownAttributes | AdTypeBreakdownAttributes | InventoryBreakdownAttributes,
) => {
  let kpiValue;

  switch (kpi) {
    case "ctr":
      kpiValue = event["clicks"] / event["impressions"];
      break;
    case "buying_price":
      kpiValue = microValueToDollarValue(event[kpi]);
      break;
    default:
      kpiValue = event[kpi];
  }

  return kpiValue;
};

export function listAllDaysInTimeseries<DataType extends CampaignBreakdownAttributes | AdTypeBreakdownAttributes>(
  data: DruidResponse<DataType>["data"],
) {
  return data.reduce((acc: string[], currentValue) => {
    const date = currentValue["timestamp"];

    if (!acc.includes(date)) acc.push(date);

    return acc;
  }, []);
}

export function transformDruidDataIntoGroupedTimeSeries<
  DataType extends CampaignBreakdownAttributes | AdTypeBreakdownAttributes,
>(data: DruidResponse<DataType>["data"]): GroupedTimeSeries {
  return data.reduce<GroupedTimeSeries>((acc, currentValue) => {
    const date = new Date(currentValue["timestamp"]);
    const currentEvent = currentValue["event"];
    const aggregatedData = "campaign" in currentEvent ? currentEvent["campaign"] : currentEvent["ad_type"];

    if (acc[aggregatedData]) {
      KPIS.forEach(kpi => {
        const kpiValue = formatKPI(kpi, currentEvent);

        if (acc[aggregatedData][kpi]) {
          acc[aggregatedData][kpi].push({ x: date, y: kpiValue || 0 });
        } else {
          acc[aggregatedData][kpi] = [{ x: date, y: kpiValue || 0 }];
        }
      });
    } else {
      acc[aggregatedData] = { buying_price: [], impressions: [], clicks: [], ap_conversions: [], ctr: [] };
      KPIS.forEach((kpi: DashboardAbsoluteKPIs) => {
        const kpiValue = formatKPI(kpi, currentEvent);

        acc[aggregatedData][kpi] = [{ x: date, y: kpiValue || 0 }];
      });
    }

    return acc;
  }, {});
}

export function fillMissingAdDataWithZeroValues(
  data: GroupedTimeSeries,
  allDaysInTimeSeries: string[],
): GroupedTimeSeries {
  Object.values(data).forEach(adType => {
    Object.values(adType).forEach(kpi => {
      allDaysInTimeSeries.forEach(day => {
        if (!kpi.find(item => item.x.getTime() === new Date(day).getTime())) {
          kpi.push({ x: new Date(day), y: 0 });
        }
      });
    });
  });

  return data;
}

export const calculateExecutiveSummary = (data: DruidOverviewResponse<OverviewAttributes>): ExecutiveSummaryData => {
  const result = data.data[0].result;

  return {
    ...result,
    buying_price: microValueToDollarValue(result["buying_price"]),
    ap_cpa: microValueToDollarValue(result["buying_price"]) / result["ap_conversions"],
    ctr: result["clicks"] / result["impressions"],
    cpc: microValueToDollarValue(result["buying_price"]) / result["clicks"],
  };
};

export function calculateTotalsForCampaignDataTable<
  DataType extends CampaignBreakdownAttributes | CombinedDataAttributes,
>(data: DruidResponse<DataType>, prevData: DruidResponse<CampaignBreakdownAttributes>, currencyCode: CurrencyCode) {
  return data.data.reduce(
    (acc: CombinedDataAttributes[], currentValue: DruidEventData<CampaignBreakdownAttributes>) => {
      const grouping = currentValue["event"]["campaign"];
      let groupTotals = acc.find((element: any) => element["campaign"] === grouping);

      const prevDataItem = prevData.data.find(item => item.event.campaign === currentValue.event.campaign);

      if (groupTotals) {
        groupTotals["buying_price"] += currentValue["event"]["buying_price"];
        groupTotals["clicks"] += currentValue["event"]["clicks"];
        groupTotals["ap_conversions"] += currentValue["event"]["ap_conversions"];
        groupTotals["impressions"] += currentValue["event"]["impressions"];

        if (prevDataItem) {
          groupTotals["prev_buying_price"] += prevDataItem["event"]["buying_price"];
          groupTotals["prev_clicks"] += prevDataItem["event"]["clicks"];
          groupTotals["prev_ap_conversions"] += prevDataItem["event"]["ap_conversions"];
          groupTotals["prev_impressions"] += prevDataItem["event"]["impressions"];
        }
      } else {
        acc.push({
          ...currentValue["event"],
          currencyCode,
          prev_buying_price: prevDataItem ? prevDataItem["event"]["buying_price"] : 0,
          prev_clicks: prevDataItem ? prevDataItem["event"]["clicks"] : 0,
          prev_ap_conversions: prevDataItem ? prevDataItem["event"]["ap_conversions"] : 0,
          prev_impressions: prevDataItem ? prevDataItem!["event"]["impressions"] : 0,
        });
      }

      return acc.map((groupTotals, index) => {
        const prev_ctr =
          groupTotals["prev_impressions"] > 0
            ? (groupTotals["prev_clicks"] / groupTotals["prev_impressions"]) * 100
            : undefined;
        return {
          ...groupTotals,
          ctr: (groupTotals["clicks"] / groupTotals["impressions"]) * 100,
          prev_ctr,
          color: LINE_COLORS[index],
        };
      });
    },
    [],
  );
}

export function calculateTotalsForAdDataTable(
  data: DruidResponse<AdTypeBreakdownAttributes>,
  kpi: CreativeTypeChartKPI,
  currencyCode: CurrencyCode,
) {
  return data.data.reduce((acc: DataTableTimeSeries[], currentValue: DruidEventData<AdTypeBreakdownAttributes>) => {
    const timestamp = new Date(currentValue["timestamp"]);
    const monthName = MONTHS[timestamp.getMonth()];
    const day = timestamp.getDate();
    const dateString = `${monthName} ${day}`;

    const kpiValue = currentValue["event"][kpi];
    const adType = currentValue["event"]["ad_type"];

    const adTypeItem = acc.find(item => item.ad_type === adType);
    if (adTypeItem) {
      adTypeItem[dateString] = kpiValue;
    } else {
      const newObject: DataTableTimeSeries = { ad_type: adType, currencyCode };
      newObject[dateString] = kpiValue;
      acc.push(newObject);
    }

    return acc;
  }, []);
}

export const transformAllInventoryData = (data: DruidResponse<InventoryBreakdownAttributes>) => {
  return data.data.reduce(
    (acc: { [key: string]: CategoricalDataItem[] }, currentValue: DruidEventData<InventoryBreakdownAttributes>) => {
      const supply_partner = currentValue["event"]["supply_partner"] as string;

      KPIS.forEach(kpi => {
        const kpiValue = formatKPI(kpi, currentValue["event"]);

        if (acc[kpi]) {
          acc[kpi].push({ x: supply_partner, y: kpiValue || 0 });
        } else {
          acc[kpi] = [{ x: supply_partner, y: kpiValue || 0 }];
        }
      });

      acc["impressions"].sort((supplyPartnerA, supplyPartnerB) => supplyPartnerB.y - supplyPartnerA.y);

      return acc;
    },
    {},
  );
};

export const groupInventorydata = (
  inventoryData: { [key: string]: CategoricalDataItem[] },
  topSupplyPartners: string[],
) => {
  let groupedInventoryData = {} as Record<DashboardAbsoluteKPIs, CategoricalDataItem[]>;

  const otherSupplyPartnersList = inventoryData["impressions"]
    .sort((a: any, b: any) => b.y - a.y)
    .slice(10, inventoryData!["impressions"].length)
    .map((val: any) => val.x);

  const otherSupplyPartnersString = otherSupplyPartnersList.join(", ");

  KPIS.forEach(kpi => {
    const top10SupplyPartners = inventoryData[kpi].filter((item: CategoricalDataItem) =>
      topSupplyPartners.includes(item.x),
    );

    const otherSupplyPartners = inventoryData[kpi].filter(
      (item: CategoricalDataItem) => !topSupplyPartners.includes(item.x),
    );
    const otherSupplyPartnersNames = otherSupplyPartners.map((item: CategoricalDataItem) => item.x);

    const otherSupplyPartnersCombinedImpressions = inventoryData["impressions"].reduce(
      (acc: number, currentValue: CategoricalDataItem) => {
        if (otherSupplyPartnersNames.includes(currentValue.x)) {
          acc += currentValue.y;
        }
        return acc;
      },
      0,
    );

    const otherSupplyPartnersCombinedClicks = inventoryData["clicks"].reduce(
      (acc: number, currentValue: CategoricalDataItem) => {
        if (otherSupplyPartnersNames.includes(currentValue.x)) {
          acc += currentValue.y;
        }
        return acc;
      },
      0,
    );

    const otherSupplyPartnersCombinedCtr = otherSupplyPartnersCombinedClicks / otherSupplyPartnersCombinedImpressions;

    const otherSupplyPartnersCombinedAbsoluteMetrics = otherSupplyPartners.reduce(
      (acc: number, currentValue: CategoricalDataItem) => {
        return acc + currentValue.y;
      },
      0,
    );

    groupedInventoryData[kpi] = [
      ...top10SupplyPartners,
      {
        x: `others: ${otherSupplyPartnersString}`,
        y: kpi === "ctr" ? otherSupplyPartnersCombinedCtr : otherSupplyPartnersCombinedAbsoluteMetrics,
      },
    ];
  });

  return groupedInventoryData;
};

export const sortSupplyPartnersBasedOnImpressions = (groupedInventoryData: TransformedInventoryData) => {
  const dataSortedByImpressions = {} as Record<DashboardAbsoluteKPIs, CategoricalDataItem[]>;

  KPIS.forEach(kpi => {
    const dataWithSupplyPartnersRank = groupedInventoryData[kpi].map((entry: CategoricalDataItem) => {
      const sameSupplyPartner = groupedInventoryData["impressions"].find(
        (topSupplyPartner: CategoricalDataItem) => topSupplyPartner.x === entry.x,
      );
      const indexOfSameSupplyPartner = groupedInventoryData["impressions"].indexOf(
        sameSupplyPartner as CategoricalDataItem,
      );

      const supplyPartnerWithIndex = { ...entry, index: indexOfSameSupplyPartner };
      return supplyPartnerWithIndex;
    });

    dataWithSupplyPartnersRank.sort(
      (a: { x: string; y: number; index: number }, b: { x: string; y: number; index: number }) => a.index - b.index,
    );

    const dataWithoutIndex = dataWithSupplyPartnersRank.map(item => {
      return { x: item.x, y: item.y };
    });
    dataSortedByImpressions[kpi] = dataWithoutIndex;
    return dataWithSupplyPartnersRank;
  });
  return dataSortedByImpressions;
};

export const addPreviousPeriodInventoryData = (
  sortedInventoryData: Record<DashboardAbsoluteKPIs, CategoricalDataItem[]>,
  previousGroupedInventoryData: Record<DashboardAbsoluteKPIs, CategoricalDataItem[]>,
) => {
  let inventorySeriesValues: {
    [key in DashboardAbsoluteKPIs]: { x: string; y: number; y1: number | string }[];
  } = {
    buying_price: [],
    ap_conversions: [],
    ctr: [],
    clicks: [],
    impressions: [],
  };

  sortedInventoryData &&
    previousGroupedInventoryData &&
    Object.keys(sortedInventoryData!).forEach((kpi: DashboardAbsoluteKPIs) => {
      inventorySeriesValues[kpi] = sortedInventoryData![kpi].map((item: CategoricalDataItem) => {
        const previousPeriodItem = item.x.includes("others")
          ? previousGroupedInventoryData[kpi].find(previousItem => previousItem.x.includes("others"))
          : previousGroupedInventoryData[kpi].find(previousItem => previousItem.x === item.x);

        const y1 = previousPeriodItem ? previousPeriodItem.y : 0;

        return { x: item.x, y: item.y, y1 };
      });
    });

  return inventorySeriesValues;
};

export const transformDataForLineChart = (
  groupedTimeSeries: GroupedTimeSeries,
  kpi: DashboardAbsoluteKPIs,
): Series[] => {
  return Object.entries(groupedTimeSeries).map(([key, value], index) => {
    const color = AD_TYPE_VALUES.includes(key as AdType) ? adTypeColor[key as AdType] : LINE_COLORS[index];
    return { label: key, color, values: value[kpi] };
  });
};
