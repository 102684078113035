import classNames from "classnames";
import { useContext } from "react";
import TrackingContext from "shared/services/TrackingContext";
import { ClickReportsLinkEvent } from "../common/tracking";
import classes from "./ReportLinks.module.css";

type Card = {
  title: string;
  description: string;
  link: string;
};

type CustomReportLinks = { [key: string]: Array<{ sectionTitle: string; cards: Array<Card> }> };

const SELF_ORG_ID = "1553";

const reportLinks: CustomReportLinks = {
  "842": [
    {
      sectionTitle: "IOS",
      cards: [
        {
          title: "Daily Performance",
          description: "Campaign metrics over 2 weeks",
          link: "https://reports.remerge.io/#client_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhLYCmAtAMYAWcATmiADTjTxKoY4DKxaG2A5lHyh+NTDAAO3GhGJC8AM0RRiAXyYYAtsWQ5i+EAFE05APQBVACoBhRiAUQEaYjXkBtUGgCeE/QS36TDTECgYA+mEBdgEACi5YACbuoAkwNOhYuAQxAEwA6nZQzhL4pACMGj5+hOhy9KoAuuqeVQZFMgJ2waEEmDT8cNgoGTh2cOQcWSAQuExgiDBy+G4gbgAcACw5DQAE1sEJEGg7ANK0mnAgDUzYmPSKymoMLb5taB38XSEGEgjoCn1NGMJpkDDM7PMEIt3NNMEJriBbvclAgVI0mFBfkdkiBvK8CO0Zp8mIdtNgoKCCOQ4JoJHAIPxsGFsDTAiAoH17qBugZyEhyABrISVfHs+JLEkQYIg0YEBJycjEbCHTpMXwyTAJAwgdSECCaI74ACszVxrX8+rZpKVFNlIAiUSYokGMD+Mm8BhiZQAIoVOcIQDyCA7LdFzSBreTQZLpZMDHAoIrlUSdUwkAb7thXQh0WKZEs8B5AyEXErFWDacEoLbyXYATQLlyzaL5UpXfQ5gtqqnm9VtAm0vpTd1S9hy1T+UK64D0AG8dVW3B2xCu9rdfODP3YMFU9yS8Ex9U+RBBWF0s5pw3Z3gXguQkunCuod31+Gt4Pd8WFKPxyByHCO3sGcmw3OV72XTtnzXEU+2IAcd2HfcyyPHAwBcGthSAq8QPDRcIPAVcCB7UCQHfBDni/H8jzpS9GznXDwMfSDFmg3tNzg7chwokcD1/FR/2VWgvDCOAJDCAS0NcTJMPrOibzYsC2yYgioKI19RTIocEQkGYSASb1LSjHB3G03TiASTh/QnE8p1UIA",
        },
        {
          title: "Creative Preview",
          description: "Top 50 creatives over last 30 days",
          link: "https://reports.remerge.io/#client_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAMwADAAiVlD2wvgAtACMqp7eQvYlIEoAuiputcElDhCc/oHBwvVymA5qVnC8jFkgPVZgiDBkLjOY3M3U2Jho+AoIyusgUIMQaMmEHQRdPRxWCdHYUJnBcAnFI9t4oAFBBLxIvABrbg1LydeJLW4QAKTJ4EBJLXhkbB3XrULzdTCvAhNahINQnfAAVjyLWoYm6ELwrhA30cSMRwV8wgCUEeOGB1hGanQPHOoLhgTgMDscwW3hxfO8GjgsACOK+gTp2AZv3+QKsw1GPM+kuC8IUwu21HmCEWwQlHn5IGlsoobRpioCyu8fwggJC6XsGq52vaVv1QpFxrF5pUuoINrSdsoCrkSpVIH4jWomu5Hz93gDhtFpvFYctUrIMqj8odcadCf42DAjjZD29WvT4ZAWaD4BD2PzF2tRdtpdpFZdwjgDbTvILesF2eDudDIMLxblpJAwh62DICQK9zrLgOq+w64STHewVdgO4SiAA=",
        },
        {
          title: "Top 50 Publishers",
          description: "Over previous week",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4ALQ5qp7ecgiYmAEJIEoAuipuVcFFDhCc/oHB9Rxw2CgZOFZwvIxZID1WYIgwZC4gzgAcACwATM0ABOYNEGi7ANJwDmpwIM3U2Jho+AoIypTtXp1o3b3UAUEEwkI0HJ6moJlNMsE5tQFgglissNwbiA7g95IoyC1qFAAUdkoQOgQuj0OFYEtFsFAIf8YKRoAALRwhETCELYOB+LH1VGgX7BXhIXgAa24lXehPiy1JEAC4PGBASy14ZGwZO+IC83UwjQITWoSDUR3wAFYAAxtfFikBEtVkjQUqnqmlIKAMhxM4QlTkObkgXkEfkQIUii3eMTdSXUMky6bBBVQJUq4lWDWJYK6kD6w14I2Yq0SvG/RzKpWQtTCAJQSk4YPAi7oHgh2OBOAwOzzRbedMeS0aOCwAK6nmBIvYEv+gXCqy1y4+7veBUKVsPaEdtMqRsEXv9ijmwsBUfeANCkLpexTkH1vBvefNpft2Gd9dz4JbtI716+4f7scgfhFc91rOBIgAuLZtiuD5rqK3ivgOu5fsWh44GAjhVhSAEzg2z7yre4HgKuOpPsBsHvkOcgjj+vDCFc1DTpe15NoueEwksUEbiAJFNEiwg9NgZAJAAIuSaEuNxvH8UwXJ8hO3BKEAA=",
        },
        {
          title: "Top 50 Campaigns",
          description: "By spend over previous week",
          link: "https://reports.remerge.io/#client_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4ALQ5qp7ecgiYmAEJIEoAuipuVcFFDhCc/oHBwkJocvVqVnC8jFkgPVZgiDBkLjOY3M3U2Jho+AoIyusgUIMQaMmEHQRdPRxWCdHYUJnBvHBqwnAQHNgh2K+Rh/VtnhQAEggR+EUrB4vJ14ktbhAApMngQEkteGRsHdetQvN1MI0CE1qEg1Cd8ABWAAMLWoYm68LwrhAoMcmIxwV8wgCUEeOG41BGDjU6B45xhqMCcBgdjmC28xPF3g0cFgAWJIMCbOwHPBSF4AGsBdZRqLgUrgmiFDLttR5ghFsFFdDlWRVWkKG0WVqAjrvLx9QaQul7FYhSKge0JSArdLZXb5U6VBaCCq1Z7KJq5NrdSAIbaTcKzVHvLGbXKHQrky7gmmPRrvdnfbn+NgwI4+Q8w6bIymY1LywnK0nKtG6+qvazm/73t2i72a5LrfHwImidWLiBx56DsIetgyAkACL3TsuXf7w9MQHPQPcJRAA===",
        },

        {
          title: "Creative Performance Credit Cards",
          description: "Ad metrics over previous 2 weeks",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwALAAiVlD2wvgAtDmqnt5C9iUgSgC6Km41wSUOEJz+gcGYDhxw2CgZOFZwvIxZIN1WYIgwZC4gzgAceQBMTQAE5gEJEGg7ANJwDmpwIE3U2Jho+AoIypRtXh1oXT3UAUEExDAEhxWBMpplgnNqAsEEsVs4cgAGHJrADsu32ZEOxzOFzgOwgAHkmDtyntLDcQHcHvJFGRmtQoMIkGhkoR2gROt0OFZDhpsFBwQQ4AkQtg4H4GQNqaBfsERCF+NgwI4BThuNV3hz4sseRAAmDxgQEsteGRsIdviAvF1MAlgo1qEg1Ed8ABWekgMRdHV4VwgX6OM2muXCBU4ZVOTLq6wDS7StmakDGhQwOzzRbeB0J7waOCwAKNCnCbrYTEFaL8qP4ZxFkuYphSkNhpUqqtKIA=",
        },
        {
          title: "Creative Performance Personal Loans",
          description: "Ad metrics over previous 2 weeks",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwALAAiVlD2wvgAtDmqnt5C9iUgSgC6Km41wSUOEJz+gcGYDhxw2CgZOFZwvIxZIN1WYIgwZC4gzgAceQBMTQAE5gEJEGg7ANJwDmpwIE3U2Jho+AoIYtQQHHcB5nBij4pkrYR2gROt0OL0ggRiDAEhxWBMpplgnNqAsEEsVs4cgAGHJrABsu32ZEOxzOFzgOwgAHkmDtyjsYgAZa63e6/Z5kV7vAZkL4/eR/ZrUKDCJBoZKArwdNBdHrUQ4abBQREEOAJELYOB+YUDB54UABCEgEQhfjYMCOZU4bjVKXA+LLKyHAII8YEBLLXhkbCHOUgLxdTAJYKNahINRHfAAViFIDEXUdeFcIENjm9XuCJrNFqcmRt1gGlz1bTtIA9Chgdnmi28ocl3g03zSFFjwm62GJBWiSrz+GcN397eJTF1meEppwOatSsaQA",
        },
      ],
    },
    {
      sectionTitle: "Android",
      cards: [
        {
          title: "Daily Performance",
          description: "Campaign metrics over 2 weeks",
          link: "https://reports.remerge.io/#client_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhLYCmAtAMYAWcATmiADTjTxKoY4DKxaG2A5lHyh+NTDAAO3GhGJC8AM0RRiAXyYYAtsWQ5i+EAFE05APQBVACoBhRiAUQEaYjXkBtUGgCeE/QS36TDTECgYA+mEBdgEACi5YACbuoAkwNOhYuAQxAEwA6nZQzhL4pACMGj5+hOhy9KoAuuqeVQZFMgJ2waEEmDT8cNgoGTh2cOQcWSAQuExgiDBy+G4gbgAcACw5DQAE1sEJEGg7ANK0mnAgDUzYmPSKymoMLb5taB38XSEGEgjoCn1NGMJpkDDM7PMEIt3CBBgkxBAElcbnd8EoECpGkwoL8jskQN5XgR2jNPkxDtpsFBQQRyHBNBI4BB+Ngwth6YEQFA+vdQN0DOQkOQANZCSpErnxJbkiDBEGjAgJOTkYjYQ6dJi+GSYJEEEDqQgQTRHfAAVmaBNa/iNnIpqupCpAESiTFEgxgfxk3gMMTKABFCjzhCB+QRnTbolaQHaqaCZXLJgY4FAVWrSfqmEhjfdsB6EFjJTIlngPCGQi5VSqwQzglAHVS7ACaBdeZaJUqlB76HMFtUM23qtpk2l9BbuhXsFXaULRY3Aehg4Tqh24F2Ib2DP2lwYh7Bghm+eXgpPqoKICKwulnHPmwu8C9lyFV0511C+wbtwRdyOD2WFBOpxAchMCKG8W0XKMVzXHs303D8o2/fcxyPStTxwMAXHrMV7HnVtP2jJ9oPADc9XgiVENHZ4/wA09GTAu8HwMKCXxgxY4PFQdiGHJCqPHY9AJUYC1VoLwwjgCQwiEjDXEybCm3A+8ByYwiWOI2DSI4ncuL3UdrhACQZhIBI/RtWMcHcPSDOwIzOCDadz1nVQgA=",
        },
        {
          title: "Creative Preview",
          description: "Top 50 creatives over last 30 days",
          link: "https://reports.remerge.io/#client_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAMwADAAiVlD2wvgAtACMqp7eQvYlIEoAuiputcElDhCc/oHBwvVymA5qVnC8jFkgPVZgiDBkLiBw2AkOmBAJIM3U2Jho+AoIyrsgUIMQaMmEHQRdPRxWCdHYUJnBcNvUUCOHeKAAkECLwkLwANbcGpeTrxJbPCABSYfAgJJa8MhrR5WLzdTDbAhNahINRXfAAVjyLR+cJuQMcmIxwV8wgCUHeOCh1hGanQPFuMNRgTgMDscwW3iJAu8GjgsACRMBgQZ2CZILBkKsw1GfIB0uCaIUosO1HmCEWwSlHkFIFl8oobRA9ICqu8oIgEJC6XsWp5uvaNsNIrFpollpU+oIdrSDsoSrkKrVIH4jWo2t5/wD3iDxvF5slEetMrIcpjiqdypdSf42DAjg5b19OszkZAOZD4DDhMLd1tJft5edjLdwjgTYz/KLBuFudD+fD0OLpYV1JAwh62DICQKrwbLjO6+wm4STD+wXdEO4SiAA==",
        },
        {
          title: "Top 50 Publishers",
          description: "Over previous week",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4ALQ5qp7ecgiYmAEJIEoAuipuVcFFDhCc/oHB9Rxw2CgZOFZwvIxZID1WYIgwZC4gzgAcACwATM0ABOYNEGi7ANJwDmpwIM3U2Jho+AoIypTtXp1o3b3UAUEEwkI0HJ6moJlNMsE5tQFgglithgkHJgII0biA7g95IoyC1qFAAUdkoQOgQuj0OFYEtFsFAIf8YKRoAALRwhETCELYOB+PH1TGgX7BXhIXgAa24lXepPiy0pEAC4PGBASy14ZGwVO+IC83UwjQITWoSDUR3wAFYAAxtYlSkBkrVUjQ0unahlIKAshxs4QlXkOfkgQUEYUQMUSm3eMTdWXUKkK6bBFVQNUa8lWHWJYKGkDG014M24u0yom/RzqtWQtTCAJQWk4cPAi7oHgRxOBOAwOzzRbebMeW0aOCwAKGgWBMvYCvBkXiqyNy4B/veFUKTsPaE9rMqVsEQfDijW0sBSfeENikLpexzkHNvBvZfttfd2G97dL4J7tIH16B8fHqcgPwRTXk2i4kiAK4dl2G4vlukreJ+I6Hn+5anjgYCOHWNIgQuLbvsqj7QeAm4Gm+4GId+Y5yBOAG8MIVzUPOt73m2q5ETCSxwTuIAUU0aLCD02BkAkAAi1JYS4/GCcJTB8kKM7cEoQA===",
        },
        {
          title: "Top 50 Campaigns",
          description: "By spend over previous week",
          link: "https://reports.remerge.io/#client_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4ALQ5qp7ecgiYmAEJIEoAuipuVcFFDhCc/oHBwkJocvVqVnC8jFkgPVZgiDBkLiBw2AkOmBCNzdTYmGj4CgjKOyBQgxBoyYQdBF09HFYJ0dhQmcG8cGrCcBAc2CFsF9Imd6gc8KAAkECPwilYPF5OvElk8IAFJu8CAklrwyGsHlYvN1MI0CE1qEg1Jd8ABWAAMLWoYm6KLwrhAUMceNxwV8wgCUDeOG41BGDjU6B4N0RWMCcBgdjmC285Ol3g0cFgAXJkMCXOwPJhSF4AGsRdZRpKIWrgtiFAqDtR5ghFsFVQj1WRNWkKG0OXqAgbvLxjSaQul7FYxRLwe0ZSA7fLFU7lW6VDaCBqtb7KLq5PrDSBYY6LeKrXHvImHUqXSr0x7glmfTr/fnA4X+NgwI4ha8o5bYxmE3Lqyna2nKvGm9q/Zz28Gfv2y4OG7L7cnwKmyfXbiBp77TsIetgyAkACIvXsuQ/H09MMEfUPcJRAA=",
        },
        {
          title: "Creative Performance Credit Cards",
          description: "Ad metrics over previous 2 weeks",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwALAAiVlD2wvgAtDmqnt5C9iUgSgC6Km41wSUOEJz+gcGYDhxw2CgZOFZwvIxZIN1WYIgwZC4gzgAceQBMTQAE5gEJEGg7ANJwDmpwIE3U2Jho+AoIypRtXh1oXT3UAUEExDAEhxWBMpplgnNqAsEEsVs5NgBOABsa12+zIh2OZwucB25T2ByOe3OSTxOwAgtgEg5MBAEtdbvdHooyM1qFBhEg0MlCO0CJ1uhwrIcNNgoOCCHAEiFsHA/OyBg88KBfsERCF+NgwI5xThuNV3vz4sthRAAmDxgQEsteGQqYKrF4uph6QRGtQkGojvgAKxskBiLomvCuEC/Rx221q4QanDapyZfXWAaXJVvbzWhQwOzzRbed28w0gDRwWABRo3EDCbrYDEFaJixP4ZyV6vYWsJJiK6OxrU6ptKIA==",
        },
        {
          title: "Creative Performance Personal Loans",
          description: "Ad metrics over previous 2 weeks",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwALAAiVlD2wvgAtDmqnt5C9iUgSgC6Km41wSUOEJz+gcGYDhxw2CgZOFZwvIxZIN1WYIgwZC4gzgAceQBMTQAE5gEJEGg7ANJwDmpwIE3U2Jho+AoIypRtXh1oXT3UAUEExDAEhxWBMpplgnNqAsEEsVs4AOwABkRADZdvsyIdjmcLnAduUdnEnDhEDsADKYYZQfE7ACC2ASDkwEASVgReTya3RByOp3OlxpRKgJIQ5Mp2GpBJiAQAsucANasQlCXhkGn0xnM1k3EB3B7yRRkZrUKDCJBoZKEdoETrdDhWQ4aCXgghwBIhbBwPwmgb60C/YIiEL8bBgRxQTLcarvG3xZYOiABMHjAgJZaqhl2qxeLqYVkERrUJBqI74ACsxpAYi68bwrhAv0cZGwqsDwmDODDTkjVjkA0ufqtMZAaYUMDs80W3kLQ+8GjgsACjR1wm62ExBWizpwLhXa8xTF9bY7ofDPaUQA",
        },
      ],
    },
    {
      sectionTitle: "Core",
      cards: [
        {
          title: "Acquisition Creative Performance",
          description: "Ad metrics over previous 2 weeks",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwALAAiVlD2wvgAtDmqnt5C9iUgSgC6Km41wSUOEJz+gcGYDhxw2CgZOFZwvIxZIN1WYIgwZC4gzgAceQBMTQAE5gEJEGg7ANJwDmpwIE3U2Jho+AoIypRtXh1oXT3UAUEExDAEhxWBMpplgnNqAsEEsVs4cgAGACsKN2+zIh2OZwucB2EAA8kwduU9gBBGLXW73R6KMjNahQYRINDJQjtAidbocKyHDTYKDgghwBIhbBwPwMgYPPCgX7BEQhfjYMCOAU4bjVd4c+LLHkQAJg8YEBLLXhkbCHb4gLxdTAJYKNahINRHfBI+kgMRdXV4VwgX6Oc1m+XCRU4FVOTIa6wDS7St7eE0KGB2eaLbyOtlakAaOCwAKNG7W7rYDEFaL8qP4ZxF4QljFMKUhsPK1VVpRAA===",
        },
        {
          title: "Retain Creative Performance",
          description: "Ad metrics over previous 2 weeks",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwALAAiVlD2wvgAtDmqnt5C9iUgSgC6Km41wSUOEJz+gcGYDhxw2CgZOFZwvIxZIN1WYIgwZC4gzgAceQBMTQAE5gEJEGg7ANJwDmpwIE3U2Jho+AoIypRtXh1oXT3UAUEExDAEhxWBMpplgnNqAsEEsVs4cgAGADMAHYEbt9mRDsczhc4DtynsBmQCTsAHJjbCIUkAQWwCQcmAgCSs8IROTW6L2ByOp3Ol1J5mJgrIgh2mwJdIZTJZ1HWeQ2GJ5OP5+MJQoCIrFOUl9MZzOut3uj0UZGa1CgwiQaGShHaBE63Q4VkOGmwUHBBDgCRCVL8FoGDzwoF+wREIX42DAjg9OG41XeDviyxdEACYPGBASy14ZHpTqsXi6mBZBEa1CQaiO+AArOaQGIuim8K4QL9HHnc2HhBGcNGnJl49YBpcg29vNmFDA7PNFt5y3bEyANHBYAFGjcQMJutgsQVou7B/hnJvt9hdwkmIHu72ozGj0ogA==",
        },
      ],
    },
  ],
  "461": [
    {
      sectionTitle: "First Time Buyers",
      cards: [
        {
          title: "Last 7 days",
          description: "Daily performance per audience in the last 7 days",
          link: "https://pivot.remerge.io/#bids_complete/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAOwAIlZQ9sL4ALQAjKqe3kL2RSBKALoqbtXBRQ4QnP6BwZgOHHDYKBk4VnC8jFkgXVZgiDBkLiDOACwAbOWNAAQAYnBg/SCN1NiYaPgKCGLUEBxnAeZwYpeKZC2EbQQdXRw9QQReHA1MI4HdcNQJlNgrNqPMEItls4AEwADnKaJ2+0ODhCxiYIQAgtgEg5MBAEiFdqYAEJWFHo5E5LEHfp4gkQADyBOpdJOIDOF3kb1u936ZCeL2F13elFaXnaaE63WoAQBIHQSogxBgUxCGjQAAtMAlxpNMjCIeAFkt8M5sDBBNReEheABrY6nc6vGWih4S546K7KflQYRINDJT4K75alUgBLRbBQC0EOAwRNkbC8SIgKD9IWgNXBAJgLOLKweGN5+K26iJgLmsYEBJLHMk35WLydE3BBrUJBqCBCgCsHyr3giVkTGmTqdC4WiVg46QdQk6HmCMXKBWo+YchZAxYIYSnVWrM6zKbG9Ygjehaag7cT3RUIEHw/wa4QTT3tajaqOFmOYlmQZYOrmcj9Go6A8NG3itgojoXHCNp9m+E7BBozxpBQHyAQE2beCIIT8NgZZOJk3DUFBDgwYemEtoE6Z2HMaEEP28FYWQOEBP2RaBEBRHBCRZC8MIAAeVi0fRcGMQmzHIWxCLeJx8nYbAfH4YJhEgYCrpuiE6T2NJ0GwXg8oIYprGoSp6Hnt4Gm4Q0/LCF02BkAkeRJteyZ2q57meUwBageBFZRBoTCGhAchCiA24AOoNEAA===",
        },
        {
          title: "Month to date",
          description: "Daily performance per audience in the last 30 days",
          link: "https://pivot.remerge.io/#bids_complete/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM4ALABsOY0ABABicGC1II3U2Jho+AoIYtQQHKcB5nBiF4pkLYRtBB1dHD1BBLw4GphHBbrhqONJsEZtQ5ggFktnAAmAAcOVR2z2BwcIWMTBCAEFsAkHJgIAkQjtTAAhKzItFIgDsmP2tVx+IgAHl8VTaccQKdzvJXjc7rUyI9nsKrm9KK0vO00J1utQAv8QOglRBiDBJiENGgABaYeoQiaZaHg8DzRb4ZzYGCCai8JC8ADWRxOZxeMtF9wlTx0l2U/KgwiQaGSHwVXy1KpACWi2CgFoIcBgibI2F4kRAUFqQtAauCATAWYWVg8Mbz8Vt1ETAXNowICUWOeJPysXk6JuCDWoSDUECFAFZ3lXvBErImNMnU6FwtErBx0g6hJ0PMFcgARQoFnggYsEMJT8rVmdZlOjesQRtQtNQduJ7oqECD4f4NcIJrUMSdW14K4h6BI4WY5iWZBlg6uZyLUajoAeE7BK2CiOucsI2n2r5IQQGhPGkFDvGqoHZt4IghPw2Blk4mTcNQsEOPBhbRt4KHpnYsyYQQ/YscEeGwAE/ZFiBASkcE5FkLwwgAB5WAxTGIZ8CaBOx6HWvC3g8ThID8QRQnAXIJHgQCrpuiE6T2HJcEIXg8qsSpaGcRpWFnt4umCT+IDCF02BkAk25JleyZ2vy3nYL5CRMPuBCluWuYREwhoQHIQogLkBRKEAA=",
        },
        {
          title: "Top 10 Ad Labels",
          description: "In the last 14 days",
          link: "https://pivot.remerge.io/#bids_complete/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwALAAiVlD2wvgAtDmqnt5C9iUgSgC6Km41wSUOEJz+gcGYDhxw2CgZOFZwvIxZIN1WYIgwZC4gznkAbDlNAAQAYnBgAyBN1NiYaPgKCGLUEBxnAeZwYpeKZK2E7QSd3Ry9QQReHA1MI4HdcNRJtNgnNqAsEEsVs4AEwADhyaJ2+0ODhCxiYIQAgtgEg5MBAEiFdqYAEJWFHo5EAdixBwGeIJEAA8gTqXSTiAzhd5G9bvcBmQni8Rdd3pQ2l4Omguj1qAEASB0MqIMQYNMQho0AALTAJCZTTIwiHgRbLfDObAwQTUXhIXgAa2Op3Or1lYoekueOiuygFUGESDQyU+iu+2tVIAS0WwUEtBCExDICGKA2FoHVwQCYDIjsiMe8Yi6duoSYCFvGBASy14JaTCa8XVNwUa1CQaggwpyAAZmtRKxA7XhXCB1Y4Sy3C2Ri6WrHIBmp0Dxy8EmwonRc4bbuyptwQNM80hQPrOAtgFwQRCF+Nhi05MtxqGuHBu86fE4E4H3eYjwIHs/3PWAAh7fNAjnO9vEfMheGEAAPVd103PAFW8XdALsYCEW8MCPFjEAIMvaCZ1g297xAV0IA9EJ0nsdDv0w7CdwAoDD0I49qlI8ioNHEBhG6bAyASApk1THAXAFUTsHEhImFzRdlyWRogA",
        },
      ],
    },
    {
      sectionTitle: "Previous Purchasers",
      cards: [
        {
          title: "Last 7 days",
          description: "Daily performance per audience in the last 7 days",
          link: "https://reports.remerge.io/#client_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAOwAIlZQ9sL4ALQAjKqe3kL2RSBKALoqbtXBRQ4QnP6BwZgOHHDYKBk4VnC8jFkgXVZgiDBkLiDOACwAbOWNAAQAYnBg/SCN1NiYaPgKCGLUEBxnAeZwYpeKZC2EbQQdXRw9QQR0GhOsQYFMQho0AALTAJcaTTLBWbUeYIRYubAwQTUXhIXgAa2Op3Or2uZFu936ZCeL3kbw+Hi87WBv3+wV4cDUwjgd1w1AmU3ZOCIXW4KIWS3wzlCMRiRJAZwudLJFIe1OeOiuyhOICgwiQaGSnyZ3xZ3WoCWi2CgiIIHK5PPuhX6StAAQBIACYDImMixu8Yk6kotEACCLGBASS14Pst5pAXk6sOCDWoSDUECV5QADAyvj4/CGNNbbaFwtErBx0pihJ0PMEYuUCtQoC6eJ7egQwhErIzvJbizaxiGw4LAVAY9g438VCB05n8DWEE0W/FJXhXB25I4fTHgl6fYsrHJ+mp0O2+8EowosRdxWjvKn/cENM80hQPu6d9g94DhCF+Gwb0nEyMVrFPc88FaE0QDIAAPAU5glFNZ0vAhX1gAJUzdQJv1/EARBCMheGEODjwg11n0jQI4FvJCHxQqoYIw99sK3PDvFxCACRCdJ7HIhwz0otCQGvWi7Hoo8CCfESWKwlcEy6bAyASPIrSHa0pR1YQlJUpg2wIA9fV7aImChCA5CVEBGwAdQaIA===",
        },
        {
          title: "Month To Date",
          description: "Daily performance per audience in the last 30 days",
          link: "https://reports.remerge.io/#client_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM4ALABsOY0ABABicGC1II3U2Jho+AoIYtQQHKcB5nBiF4pkLYRtBB1dHD1BBOg0J1iDBJiENGgABaYerUcaTYIzahzBALFzYGCCai8JC8ADWRxOZxeVzINzutTIj2e8le7w8XnaQJ+f2CvDgamEcFuuDhE0ybJwRC63GR80W+GcoRiMUJIFO51ppPJ9ypTx0l2UxxAUGESDQyQ+jK+zO61AS0WwUAFBHZnO5d0KtUVoAC/xAATAZAxkSN3jEnQl5ogAX5owICUWvG9FrNIC8nRhwQa1CQaggipyAAZ6Z8fH5gxorTbQuFolYOOkMUJOh5grkACJOhwuj29AhhCJWBneC1F62jYOhhEAqDR7Cx34qEBpjP4asIJrUAMQCV4VxtuSOb3R4Ke70LKxyWpqdA8P3BSMKTHnMWo7wpi8EDRPNIUd5u7fYXcA4QhfjYF6TiZKK1gnmeeCtMaIBkAAHvCszism049sEL6wAEKauoEX4/iAIghGQvDCLBR7ga2qERoEcA3oh97IeU0HoW+WGbrh3g4hA+IhOk9hkQ4p4UXmV40XYdGHgQj6USAzGYUu8ZdNgZAJA2loDlakrasIinKUwzp7mQXo+t20RMJCEByIqIC5AUShAA",
        },
        {
          title: "Top 10 Ad Labels",
          description: "In the last 14 days",
          link: "https://reports.remerge.io/#client_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwALAAiVlD2wvgAtDmqnt5C9iUgSgC6Km41wSUOEJz+gcGYDhxw2CgZOFZwvIxZIN1WYIgwZC4gznkAbDlNAAQAYnBgAyBN1NiYaPgKCGLUEBxnAeZwYpeKZK2E7QSd3Ry9QQR0GgusQYNMQho0AALTAJCZTTLBObUBYIJYubAwQTUXhIXgAa2Op3Or2uZFu9wGZCeL3kbw+Hi8HWBv3+wV4cDUwjgd1w1Em03ZOCI3W4KMWy3wzlCMRiRJAZwudLJFIe1OeOiuyhOICgwiQaGSnyZ3xZPWoCWi2CgiIIQmIZAQxQGStAAQBIACYDImMixu8Yi6kotEACCPGBASy14Pst5pAXi6sOCjWoSDUECVAFZmtRAxBJXhXJ7Ao4fTHgl6fUsrHIBmp0Dx/cEowosRdxWjvKnmwQNM80hQPu6y9gK4DhCF+NhvU5MmLrPXG3g2iaQGQAB4C+YSlMqXsgfuwAKpt2lgJj7wiEJkXjCDe1peug+tuDtnddvfVNdHwenktyKO44gLiEAEiE6T2I+DgNs+jLeK+76djWBA9vBwS/ieuYJt02BkAkBRWjaOAuDqwi4fhTAupWZDer6jRAA===",
        },
      ],
    },
  ],
  "1553": [
    {
      sectionTitle: "",
      cards: [
        {
          title: "Creative Performance",
          description: "Top 5 creatives by type, over the last 30 days",
          link: "https://reports.remerge.io/#client_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAMwADAAiVlD2wvgAtACMqp7eQvYlIEoAuiputcElDhCc/oHBmA4ccNgoGThWcLyMWSA9VmCIMGQuIM6VAKwbOc0ABCwIcrsAYj0jvBCIIM3U2Jho+AoIYtQQHHcB5nBij4pkbYQOgQuj0OH0ggQ4Alwh1qFMZsF5tRFghlqt7GphPUKDcQHcHng0A5lq93oMyF8fvI/i1qFAsRA0MlAV5OkTQVYEtFsFBMsEoTDWXTBgTQAEIXNMQEoLycNwaqzgfEVpyIAFpnyCAkVrwyNgub1qF5upgEsEmtQkGpGfgNgCPIqQCDDSAuRoeZqQALsHA/MKHKKQOLEVKVrKeVYHd4xN0VdQueqEVqdXqDWCjfFTeaVCArTa8HbcTGICq8K4g4FHHrdSHhNLw/LrIM1OgeCzvNqFDA7Aslt4Le3ghpvmkKADxVXsDWCLwkLwANaNuTN1t4dqOztwbsPZF97MK7zD2ABC1iysBKfeWcQBchdL2KzLhwtwNR4Kb7e91H9nNvghH0dTwrORJ2nL1hBCfhsDARwG0fFdXyBV1Ai3Htd2/fdB3/MgRxPcdz2rbwRBCMheGEAAPeDn1XdcOxQz90OWTC/xAAC8MoM8QIvMCAhg7AmOoJ8XzbFiPzQ8A9wIAcWLYsdcWEHpsDIBICm5OCy3kxTlKYEVa3rTJuCUIA",
        },
      ],
    },
  ],
  "1611": [
    {
      sectionTitle: "iOS",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AGw5OY0ABAAycDI4IQBSIoMgjdTYmGj4Cghi1BAcVwHmcGK3imQthG0EHV0OD0ggQ4DAEhAyNheJEQONJsEZtQ5ggFktnAAWAAMqwArNs9gdsMdTsSIAB5JhbAAUuJydIAnDkABw5ADMAEpzpdrp97mRHs9amQ3h95F8mtQoMIkGhkr8vO00J1utQIRpsFBMsFhDBSNAABaOEIiYQhbBwPxS2o3PCgAIgkC8JC8ADW3HKiv+8UWVghAQm2oICUWMOwENVIC8nUw9QIDWoSDUEFtuKxkpAYk6vrwrhADscUJhiLUwgCUC1OA91lqanQPAV3hDChgdlm828CcbwQ07zSFB+BYC0O8zogburclr9bt3eDgTBbeRHeCXY8XpAvdgAQT9sChZHwX42DAjkrmqsU4cddtrQ3zcXN2XqM7Kjnm7IfZ3g/3w+LBH4IpL2nW93wfVsn3AFd4zfddvC3ftd3zX8i1HYQ4GA68ZzvJsFwg9sX1XWC/g/L8BwuKMumwMgEgAEWiTVMhcCjhComimBtI8XXdBogA===",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AGw5OY0ABAAycDI4IQBSIoMgjdTYmGj4Cghi1BAcVwHmcGK3imQthG0EHV0OD0ggQ4DAEhAyNheJEQONJsEZtQ5ggFktnAAWAAMqwArNs9gdsMdTsSIAB5JhbAAUuJydIAnDkABw5ADMAEpzpdrp97mRHs9amQ3h95F8mtQoMIkGhkr8vO00J1utQIRpsFBMsE4AkQkV0DBuFLajc8KAAiDpmphAEoFqcMaFd4xJ1FlYIQEJtqCAlFjDsBDVSAvJ1MPUCA1qEg1BAzbifh5Ff9lYCPdFNT6QEJiGQEIVTTwQJbETa7Q7NVYky74u61RAvQjff6oUGgdRQ4lglHs744/gE5RWsmQADg+qoRWdfUTQ4zRbegRfLbFhWndX2rWnZ6yN7Rs2oAG21ZO+Huype7H45LR1v8K5i4FHFCYaWV/bMk65LU1OgixvmwUGA7FmeZvB7ACQA0d40goG9hC6bAyASAARDM13vC4Q0Q5CmELJcy1XT8GiAA",
        },
      ],
    },
    {
      sectionTitle: "Android",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AGw5OY0ABAAycDI4IQBSIoMgjdTYmGj4Cghi1BAcVwHmcGK3imQthG0EHV0OD0ggQ4DAEhAyNheJEQONJsEZtQ5ggFktnAAWAAMqwx2z2B2wx1ORIAgtgEg5MBAElsABQAK2EADp+MyhITmYNKdSEgBKc6Xa6fe5kR7PWpkN4feRfJrUKDCJBoZK/LztNCdbrUCEabBQTLBYQwUjQAAWjhCImEIWwcD8CtqNzwoACIJAvCQvAA1txyur/vFFlYIQEJoaCAlFjCKYCrF5Oph6gQGtQkGoIM6AKxY+UgMSdYN4VwgN2OKEwxFqYQBKAGnB+6y1NToHhq7xRhQwOyzebeVPt4Iad5pCg/MsBaHeT0QH2NuTN1suweRwJgnvIvvBAceAMgYewAKp12BctT4L8bBgRz1/VWBcOFvO1p7zvrm6b1H9lQr/dkEdHuOp6TpWBD8EU96Ls+v5vt2H7gFuKY/ru3gHqOx6lsBFbTsIcCQY+S4vh2a5wb2X7bshfx/gBY4XCAwhdNgZAJAAItE+qZC4dEMdgTEJEwToXl6voNEAA=",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AGw5OY0ABAAycDI4IQBSIoMgjdTYmGj4Cghi1BAcVwHmcGK3imQthG0EHV0OD0ggQ4DAEhAyNheJEQONJsEZtQ5ggFktnAAWAAMqwx2z2B2wx1ORIAgtgEg5MBAElsABQAK2EADp+MyhITmYNKdSEgBKc6Xa6fe5kR7PWpkN4feRfJrUKDCJBoZK/LztNCdbrUCEabBQTLBOAJEJFdAwbgK2o3PCgAIg6ZqYQBKAGnCWtXeMSdRZWCEBCaGggJRYwimAqxeTqYeoEBrUJBqCA2gCsPw86v+mojOui+qDICExDICEK1p4IHtiKdLrd+qsGa98V9uYDCODoahEO1ICjiWC8cLvmT+DTlFamZAAJ7uqhdaN9StDhtdt6BF8zsWdY9jfazY9/rIgdGHagYe7QOofZjA5UQ6TqflU/3+FclcCjihMOrm9dmQ9ci1Go6AVruHYKDAdizPM3iDmBIAaO8aQUE+whdNgZAJAAInm26vhcvboZhTDluuNZbv+DRAA==",
        },
      ],
    },
  ],
  "1458": [
    {
      sectionTitle: "iOS",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45ACwArAAcjQAEAHKmpjtMAmpwATvmtcIAdCEA8sRk7jsAsmQAhCCN1NiYaPgFAgxNQIBw/gFzHAxIDFGQWoQ2gQOl0OD0ggQ4DAEhAyNheJEQONJsEZtQ5ggFktnKsAAybABMu0ezzen32ACEQhB7kwdgAKHIANgAzIyAOwMxlCgCU31+/1hwLIoPBtTIUJh8jhTWoUGESDQyURXnaaE63WoOI02CgmWCwhgpGgAAtHCERMIQtg4H49bUAXhQAEMSBeEheABrbjlU3I+KLKw4gITe0EBKLAnYHGWkBeTqYeoEBrUJBqCCB9a03UgMSdRN4VwgEOOPEE0lqYQBKB2nAx6y1M6B1pxkAZhQwOyzebeEsm7waaFpCgIlsBfHecMQKP9uSD9A8efBcdYqfkmfBOceUeL2ABEvBwKtjfBfjYMCOXu2qx7hxDw/Xt4J6TgC56UrOKhHgQt7Lg+zZPuu7YEPwRQ/vuw5QWOgSnqB4AXsWkGAcEMH3quCFtpuwhwGhf4HkGmHAWeeHgZehFIiAJErj8eZdNgZAJAAItEtqZC43HCLx/EnA4gZhhG0YNEAA=",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45ACwArAAcjQAEAHKmpjtMAmpwATvmtcIAdCEA8sRk7jt5ZACEII3U2Jho+AoEGJqBAOL8AuY4GIAYoyC1CG0CB0uhwekECHAYAkIGRsLxIiBxpNgjNqHMEAsls5VgAGTYAJl2j2erw++wAQiEIPcmDsABQ5ABsAGYGQB2ekMwUASi+Pz+MKBZBBYNqZEh0PksKa1CgwiQaGSCK87TQnW61GxGmwUEywTgCRCRXQMG4utq/zwoAC6OmamEASgtpwbuN3jEnUWVmxAQmdoICUW+Ow2ItIC8nUw9QIDWoSDUEE963hHhNSLNKOj0Rt8ZAQieCEKHp4IB9JP9geDNqspfD8SjlogseJCaTuNTqOoGcSwVzdd8hfwxcorTLIGRaatuK79vq7ocnu9vQIvgDiy7od77X7oZjZDjo1HUGTE6s06zs5U84LRZ165v+CuK2gSOLi+LtmeQaZKGci1Gch5hsEiYKDAdizPM3hzleBAaFCaQUH+whdNgZAJAAItWF6Ad86bEaRJwHhBnbQQ0QA",
        },
      ],
    },
    {
      sectionTitle: "Android",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45ACwArAAcjQAEAHKmpjtMAmpwATvmtcIAdCEA8sRk7jsAsmQAhCCN1NiYaPgFAgxNQIBw/gFzHAxIDFGQWoQ2gQOl0OD0ggQ4DAEhAyNheJEQONJsEZtQ5ggFktnKsAAybADMu0ezzen32ACEQgBBbAJByYCAJHYACn4ahu2DYUFO5zINwGGhug35goSN0wT3cGgATABKb6/f6w4FkUHg2pkKEw+RwprUKDCJBoZKIrztNCdbrUHEabBQTLBYQwUjQAAWjhCImEIWwcD8DtqALwoACGJAvCQvAA1txyu7kfFFlYcQEJoGCAlFgS+airF5Oph6gQGtQkGoIMn1rT7SAxJ1i3hXCA0448QTSWphAEoAGcHnrLUzsnWgWQFWFDA7LN5t5W27vBpoWkKAjRwF8d5MxAcwu5Ev0DwD8EN1jt+Td8F9x410fYAFW1TQIx0vYJ+GwMBHDnf0rHvBxlyfH9vFfLcAQ/Sk9xUZ8CD/E9AJHYCLwnAh+CKWCHxXbD10CN80PAT8WywpDglwgCz0I8cr2EOByPgx8UyolD33ojCvyYpEQFY08fhAYQumwMgEgAEWif1MhcGS5OwBSEhOBxkwzLNcwaIA==",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45ACwArAAcjQAEAHKmpjtMAmpwATvmtcIAdCEA8sRk7jt5ZACEII3U2Jho+AoEGJqBAOL8AuY4GIAYoyC1CG0CB0uhwekECHAYAkIGRsLxIiBxpNgjNqHMEAsls5VgAGTYAZl2j2erw++wAQiEAILYBIOTAQBI7AAU/DUN2wbCgp3OZBuAw0N0GfIFCRumCe7g0ACYAJRfH5/GFAsggsG1MiQ6HyWFNahQYRINDJBFedpoTrdajYjTYKCZYJwBIhIroGDce21f54UABdHTNTCAJQf04COu7xiTqLKzYgITAMEBKLfG8lFWLydTD1AgNahINQQaPreEeN1Ij3l73RP2FkBCJ4IQpRnggOMkxPJ1N+qxtzPxHPd/PEosl3HYr0gSuJYJ1/u+Jv4FuUVrtkDIzc+3HTwP1SMOaOx3oEXxJxbT9Nz9oL9N5sgF0ZVygUsN1Raht2rXcVH3Rtmztc8f3wVwx0CRxcXxCc3xTTJ0zkWozkfDNgmLBQYDsWZ5m8PcvwIDQoTSCh4OELpsDIBIABEew/JDvi3Fi2JOB9MKnHCGiAA===",
        },
      ],
    },
  ],
  "1583": [
    {
      sectionTitle: "iOS",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AKwAHADMjQAEANIA8gByAIIAsgCSIWcnABIAtABSxwDiOwBiFy+3pjvmADLGABCOyYJUa1GwmDQ+AUCDE1AgHChAXMcDEsMUZBahDaBA6XQ4PSCBDgMASEDI2F4kRA40mwRm1DmCAWS2cABYAAxcgDsuxuD2eRzen2+vwBwNBwhCEAOTB2AAoAGzcrkAJk23PWAEoQBCQFCYfIsYjkbUyGiMSb4diDVBhEg0MlcV52mhOt1qBSNNgoJlgsIYKRoAALRwhEQy7BwPzUKC1Y2gAIkkC8JC8ADW3HKbvx8UWVgpAQmAYICUWNOwFK9IC8nUw9QIDWoSDUEGNqy5TXjBZdKccVJpjLUwgCUH9OBz1lqanQPFd3grChgdlm828LcXwQ06LSFBxA4C1O86YgWencln87wrTzIGXZLXzI3wS3Hnvu9gARbycCg5PYJ+GwMBHEnP0rCvBw5yTbdy0CJ8YRfVlNxUOCQC/fdfxAI8h1PTAikg69YI/JcENXJDwFfZs0NIncyD3H9D3/Y9hwIXhhDgIjoJvO8yJXZ8qJQt9aLxDCGO/A8DWELpsDIBIABFoj9TIXGk2T5KYRMgIzbMGiAA==",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AKwAHADMjQAEANIA8gByAIJ5AJIheScAEgC0AFLHAOI7AGLnzzemO+YAMsYAEI7JglRrUbCYND4BQIMTUCAcSEBcxwMQwxRkFqENoEDpdDg9IIEOAwBIQMjYXiREDjSbBGbUOYIBZLZwAFgADJyAOy7a73J5HV4fL4/f5AkHCEIQA5MHYACgAbFzOQAmTZc9YAShA4JAkOh8kxCKRtTIqPRxrhWP1UGESDQyRxXnaaE63Wo5I02CgmWCcASISK6Bg3GoUFqRtAAWJ0zUwgCUD9OHDLu8Yk6iys5ICE39BASi2p2HJnpAXk6mHqBAa1CQaggRtW2I8rrx7oJOeivoLICExDICEKUZ4IFjDITSZTvqsbYz8WzXogefpheLlLLhOolcSwTr/d8TfwLcorXbIHx5e9lJnAfqEdHeBjvQIvkTixnafn7UXadzZD5qM65QCWW5WLu1b7ioh6Ns2TQRn++CuOOgSOJS1KTh+yaZGmci1Go6Bjj+64KDAdizPM3gHiRIAaGiaQUAhFZdNgZAJAAIj2X7IfqwisexTBPvG2E8UoQA==",
        },
      ],
    },
    {
      sectionTitle: "Android",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AKwAHADMjQAEANIA8gByAIIAsgCSIWcnABIAtABSxwDiOwBiFy+3pjvmADLGABCOyYJUa1GwmDQ+AUCDE1AgHChAXMcDEsMUZBahDaBA6XQ4PSCBDgMASEDI2F4kRA40mwRm1DmCAWS2cABYAAxcgBsuxuD2eRzen2+vwBwNBwhCJ2wCQcmAgCR2AAoAFbCAB0AGscHA1BAtWo5LwoMIAJQgCEgKEw+RYxHI2pkNEYh3w7E281INDJXFedpoTrdagUjTYKCZYLCGCkaAAC0cIREMuwBtpUFq9tAARJIF4SF4Ou45UD+PiiysFICE2jBASixp8sJVi8nUw9QIDWoSEN9tWXKa1DEnSreFcIDzjipNMZamEASgUZwpestTU6B4Ae8jYUMDss3m3h7O+CGnRaQoOOnAWp3kLEGLa7kG63eFa5ZAe7Jh+Zx+CU8PC/C9YACHtc0CGd72CfhsDARwV0jKxXwcTcczPBtAl/GF/1ZE8VEwkBQKvCCpygu85wIfgihQt8MOA3dsIPXDwAA7tCMY88yEvcCbwo2cH2EOA6LQ99PyY/c/zY/DAM4vFiJ4sDrxtYQumwMgEgAEWiSNMhcVT1M0phs1gosSwaIA==",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AKwAHADMjQAEANIA8gByAIJ5AJIheScAEgC0AFLHAOI7AGLnzzemO+YAMsYAEI7JglRrUbCYND4BQIMTUCAcSEBcxwMQwxRkFqENoEDpdDg9IIEOAwBIQMjYXiREDjSbBGbUOYIBZLZwAFgADJyAGy7a73J5HV4fL4/f5AkHCEInbAJByYCAJHYACgAVsIAHQAaxwcDUEE1ajkvCgwgAlCBwSBIdD5JiEUjamRUej7XCsdazUg0MkcV52mhOt1qOSNNgoJlgnAEiEiugYNxqFBanbQAFidM1MIAlBIzgk/7vGJOosrOSAhMowQEotqXKCVYvJ1MPUCA1qEgDXbVtiPAG8UHG6HohHqyAhMQyAhCqmeCAMwzs7n8xGrP3i/EyyPK/Sa3XKeSQyBm4lgh2J74ID2+7iQPjj2HKavo/Vk3O8OnegRfDnFqvCw3dot0LCsyCrUZ9ygesj0JahT1bc8VEvbt8F7L0QPwVwF0CRxKWpJc/zzTJCzkWo1HQecgP3BQYDsWZ5m8C9qJADQ0TSCgmngrpsDIBIABFRwArDrWEHi+KYD8syI4SlCAA==",
        },
      ],
    },
  ],
  "715": [
    {
      sectionTitle: "iOS",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM4A7DkArI0ABOJwANYw9thbAOIOmDDClFsAkti8AHQhuwdHWzFw7iCN1NiYaPgFAgxNQIBw/gFzHAxIDFGQWoQ2gQOl0OD0ggQ4DAEhAyPdIiBxpNgjNqHMEAsls4AMwrAAsADZti9Dnj3p8QhAAPJMLYAChyOWpAE4Vut1gAmCXCgCU31+/1hwLIoPBtTIUJh8jhTWoUGESDQyURXnaaE63WoOI02CgmWCwhgpGgAAtHCERMIQtg4H49bUAXhQAEMSBeEheHtuOVTcj4osrDiAhN7QQEoteHicZaQF5Oph6gQGtQkGoIIH1gAGXUgMSdBN4VwgEOOPGZklqYQBKB2nDR6y1NToHgm7zphQwOyzebeYuj4IaaFpCgIlsBfHBcMQSP9uSD4dB+dpwJYqdkmfBOceWMgRewALF4OBVsbgj8bBgRy921WPcOIeBq0N7jqeALnhSs4qEet5kEuD6rs+67tm+mBFL++6AdBIGTmB4AXkWUHXt4d7Lo+zaIW23i8MIcDof+B5AWOJ44dOEGXoRSIwXBK4/LmXTYGQCQACLRLamQuLxwj8YJTABpuEZRg0QA",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM4A7DkArI0ABOJwANYw9thbAOIOmDDClFsAkti8AHQhuwdHWzFw7iCN1NiYaPgFAgxNQIBw/gFzHAxIDFGQWoQ2gQOl0OD0ggQ4DAEhAyPdIiBxpNgjNqHMEAsls4AMwrAAsADZti9Dnj3p8QhAAPJMLYAChyOWpAE4Vut1gAmCXCgCU31+/1hwLIoPBtTIUJh8jhTWoUGESDQyURXnaaE63WoOI02CgmWCcASISK6Bg3D1tQBeFAAQx0zUwgCUDtOHdJu8Yk6iysOICE3tBASi14eJxlpAXk6mHqBAa1CQaggXvWCI8puR5tRMeitoTICExDICEKnp4IF9JIDQZDtqsZYj8WjVogceJieTqar1EziWCefrviL+BLlFa5ZAKPT1rxPYd9Q9Di9Pt6BF8gcWPbD/fag7DsbI8dG46gKewabR0/i2bnKgXheLuobre+CuO2gSOHiKaduewaZGGci1Go6Btte44KDAdizPM3jzqhIAaNCaQUIBwhdNgZAJAAIjWl4gT8GZkRRTCtqeXYXnBDRAA=",
        },
      ],
    },
    {
      sectionTitle: "Android",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM4A7DkArI0ABOJwANYw9thbAOIOmDDClFsAkti8AHQhuwdHWzFw7iCN1NiYaPgFAgxNQIBw/gFzHAxIDFGQWoQ2gQOl0OD0ggQ4DAEhAyPdIiBxpNgjNqHMEAsls4AMwrAAsmx2+0OePenxCAEFsAlzhAElsABQAK2ED34D3SrzxD2EnwAlN9fv9YcCyKDwbUyFCYfI4U1qFBhEg0MlEV52mhOt1qDiNNgoJlgsIYKRoAALRwhETCELYOB+A21AF4UABDEgXhIXh7bjlc3I+KLKw4gITR0EBKLXh4nHWkBeTqYeoEBrUJBqCDB9YABn1IDEnSTeFcIDDjjx2ZJamEASgDpwsestTU6B4Zu8mYUMDss3m3lL4+CGmhaQoCLbAXxwUjEGjg7kw9HIcXGcCWJnZLnwQXHnjIGXsACpdDgXbW4I/GwYEc/ftVgPDgjsGrR3pO54ApeFLzioJ73mQK5Puur6bp2H6YEU/6HsBsFgdOEHgFeJYwbe3gPquz6tshHbeLwsqYYBR4gROZ54bOUHXsRSJwQha4/PmXTYGQCQACLRPamQuHxwgCUJTBBtuUYxg0QA==",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM4A7DkArI0ABOJwANYw9thbAOIOmDDClFsAkti8AHQhuwdHWzFw7iCN1NiYaPgFAgxNQIBw/gFzHAxIDFGQWoQ2gQOl0OD0ggQ4DAEhAyPdIiBxpNgjNqHMEAsls4AMwrAAsmx2+0OePenxCAEFsAlzhAElsABQAK2ED34D3SrzxD2EnwAlN9fv9YcCyKDwbUyFCYfI4U1qFBhEg0MlEV52mhOt1qDiNNgoJlgnAEiEiugYNwDbUAXhQAEMdM1MIAlAHThPWbvGJOosrDiAhNHQQEoteHicdaQF5Oph6gQGtQkGoID71giPObkZbUXHovakyAhMQyAhCt6eCB/SSgyGw/arBWo/FYzaIAnicnU+ma9Rs4lggXG74S/gy5RWpWQCjM7a8X2nfUvQ4fX7egRfMHFn2I4P2sOI/GyInRpOoGnuTOs/FcwuVEvi6W+pbve+CuJ2gSOHiabdpeoaZBGci1Go6Adrek4KDAdizPM3iLmhIAaNCaQUEBwhdNgZAJAAInW16gT8WbkZRTDtuePZXvBDRAA===",
        },
      ],
    },
  ],
  "1331": [
    {
      sectionTitle: "iOS",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AMyrOY0ABObuAxpQIcZqcGowsH6N1NiYaPgKCGLUEBw3AeZwYveKZC2EbQQOl0OD0ggQ4DAEhAyNheJEQONJsEZtQ5ggFktnKsAKzrbbHU7nGAaEIQADyTC2AAo1gAmbEAFmxAHYABy05kAShAVxANzu8h+z1etTIHy+gsev15UGESDQyX+XnaaE63WoUI02CgmWCwhgpGgAAtHCERMIQthTvCoLUBaAAmCQLwkLwANbccrKwHxRZWKEBCa6ggJRZw7BQ9UgLydTD1AgNahINQQAXYgAMTWoYk6frwrhAjscMLhyLUwgCUB1OE91lqJ3tSu8oYUMDss3m3kTTeCGk+aQofyLAVh3hdEHdtbk9fQPB7IcCEPbqM7wW7Hm9ID7sACiYdgWLo+C/GwYEc1e1VmnDgbc43zcXbbuK/RXZU863ZH7u6HB5HpYIfgiivGdG3vYIWyXZ9wFXBN33AghtwHPdCz/Esx2EOAQJvWc8FaTdIKfDtXzXeCAU/b9B15YQumwMgEgAEWibVMhcajaPopg7WPV0PQaIA",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AMyrOY0ABObuAxpQIcZqcGowsH6N1NiYaPgKCGLUEBw3AeZwYveKZC2EbQQOl0OD0ggQ4DAEhAyNheJEQONJsEZtQ5ggFktnKsAKzrbbHU7nGAaEIQADyTC2AAo1gAmbEAFmxAHYABy05kAShAVxANzu8h+z1etTIHy+gsev15UGESDQyX+XnaaE63WoUI02CgmWCcASISK6HOhVqAtAATB0zUwgCUB1OG45WVgPiiysUICE11BASizh2Ch6pAXk6mHqBAa1CQaggAuxfw8LpAQODmphDqmQmIZAQpoc5pAluRNrtmadSu8Yk67o1EC9SN9/phQZB1FDiWCUZAMbj+ATlFaydTbZA6e1PoR9WoUDNPCLvQIvlti3LViTVbdFc9ZG9oybUADrasHfDXZUPd8fbwCZlW/wrgXckcMLhJZX9syFbktROhY3wR+goMB2LM8zeN2AEEBonxpBQTTtl02BkAkAAi0QTo6D68sISEoUwc5LqWq5fg0QA=",
        },
      ],
    },
    {
      sectionTitle: "Android",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AMyrOY0ABObuAxpQIcZqcGowsH6N1NiYaPgKCGLUEBw3AeZwYveKZC2EbQQOl0OD0ggQ4DAEhAyNheJEQONJsEZtQ5ggFktnKsAKyrABM22Op3OMA0IQAgtgEg5MBAElsABQAK2EADp+Oy9qdFqzScSLmQAJQgK4gG53eQ/Z6vWpkD5fSWPX6iqDCJBoZL/LztNCdbrUKEabBQTLBYQwUjQAAWjhCImEIWw3MKtQloACYJAvCQvAA1txytrAfFFlYoQEJqaCAlFnCqcCrF5Oph6gQGtQkGoIBLsQAGJrUMSdUN4VwgD2OGFw5FqYQBKAmnAB6y1E5urXeGMKGB2WbzbzpjvBDSfNIUP4VgKw7zeiB+5tyVvoHhD6OBCG91H94KDjxBkAj2ABdPuwKV6fBfjYMCORvGqyLhxtld7zvrnt3LfogcqVcHsijseE5nlO1YEPwRQPku7avsEXYbp+4Dbmmv6wQQh5jie5YgVWM7CHAUFPsueCtPu8Efn2347qhAL/oB46isIXTYGQCQACLRMamQuIxzGsUwrqXj6/oNEAA=",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AMyrOY0ABObuAxpQIcZqcGowsH6N1NiYaPgKCGLUEBw3AeZwYveKZC2EbQQOl0OD0ggQ4DAEhAyNheJEQONJsEZtQ5ggFktnKsAKyrABM22Op3OMA0IQAgtgEg5MBAElsABQAK2EADp+Oy9qdFqzScSLmQAJQgK4gG53eQ/Z6vWpkD5fSWPX6iqDCJBoZL/LztNCdbrUKEabBQTLBOAJEJFdDnQq1CWgAJg6ZqYQBKAmnDccrawHxRZWKEBCamggJRZwqnAqxeTqYeoEBrUJBqCAS7F/Dw+kBA/UgQ0wj1TITEMgIW0Oe0gR3Il1uwterXeMSdf0GiBBpGh8MwqG5mOJYKJkDJ1P4dOUVpZnMgttGwtm+rUKB2nhV3oEXyuxb1qyZpt+huBsjB0ZdqAR3szkD9uODlTD3yjvDplUH/CuNdyRwwuE1rfuzIGzkWoTkrPdgjDBQYDsWZ5m8IdwIIDRPjSCgmmoYQumwMgEgAEWiY1APfUVMOwbCEiYFcN1rbciKUIA==",
        },
      ],
    },
  ],
  "1643": [
    {
      sectionTitle: "iOS",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AGwALADMjQAE5u4DGlAhMZ3YvItQezjYZBMAhDviZHgAIhBgFI3U2Jho+AoEGJqBAOL8AuY4GIAYoyC1CG0CB0uhwekECHAYAkIGRzpEQONJsEZtQ5ggFktnOscusAAy7U5dC5QK7mG53NCPZ5vD5kEIAKRiIQgAHkmDsABQ5HKbdbrABMAE5NrTaQBKEDfEC/f7yWEgsG1MiQ6F6oFwrVQYRINDJBFedpoM6o6jYjTYKCZYLCGCkaAAC0cIREwhC2DgfmoUFqutAAXRIF4SF4AGtuOUHUj4osrNiAhMvQQEosLthsd1qF5Oph6gQGtQkGoILqAKy0ppR7N2+OOXEXYlqYQBFmZdPWWpqdA8e3eYsKGB2Wbzbz1mfBDRQtIUeE9gJ44JJiCpsdyCdTvCtTMgOeYxek5fBVceK8b2ABetxwK9/cEfjYT5OKOVing4k6xmuRaBLe/z3uSK4qBBICvluH4gLufbePwRTAWe4HPrOUELjB4APnWCH4euZCbu+O5fnu/a/sIcA4aB56XgR853iRcGPuRiJIVRb7blqwhdLcCTvO6no4C4IliWQCRMDGB7JmmDRAA===",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AGwALADMjQAE5u4DGlAhMZ3YvItQezjYZBMAhDviZHgAIhBgFI3U2Jho+AoEGJqBAOL8AuY4GIAYoyC1CG0CB0uhwekECHAYAkIGRzpEQONJsEZtQ5ggFktnOscusAAy7U5dC5QK7mG53NCPZ5vD5kEIAKRiIQgAHkmDsABQ5HKbdbrABMAE5NrTaQBKEDfEC/f7yWEgsG1MiQ6F6oFwyitLztNBnVHUALogkJcJtaiEzLE3Ck+aLfDOQhkNTCIT2TU/P74W0LA3g41QnSA5RaqAhiBoZII61I20oqzYjTYKCejEuoroGDcahQWq60CO4nBgIszJVrPeMSdP3UbEBCYlkAJRYXbDY7rULydTD1AgNahINTp/AAVnhHmzIGR48H0SLA6ExDICEKtZ4IAbBF8wmbxZwbfXHfi3Z3faJBCHUBHY/tIEniWCc4gAuS54MutJroim65tuBa4reUxwPU1anng9a9JeTaXK2VgPu0T5tr2HIDh+X55hO8TTgBKhAb4IGrim+H+mhciOLiFyNteWF3lYci1Go6Bnrh76BJidizL6VHlBuGhQmkFBNBOXS3Ak7yFvBLhasISlkAkTAodMmEttxShAA=",
        },
      ],
    },
    {
      sectionTitle: "Android",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AGwALADMjQAE5u4DGlAhMZ3YvItQezjYZBMAhDviZHgAIhBgFI3U2Jho+AoEGJqBAOL8AuY4GIAYoyC1CG0CB0uhwekECHAYAkIGRzpEQONJsEZtQ5ggFktnOsctTdqcuhcoFdzDc7mhHs83h8yCEAFIxEIAQWwCQcmAgCR2AAoAFbCAB0/EVBzgR3lwjOjKg/GwtwmdW5AEoQN8QL9/vJYSCwbUyJDoZagXDTVBhEg0MkEV52mgzqjqNiNNgoJlgsIYKRoAALRwhETCELYVX4qC1C2gALokC8JC8ADW3HK3qR8UWVmxAQmoYICUWFxFKKsXk6mHqBAa1CQaggFoArAAGJrUMSdMt4VwgTOOXEXYlqDWXTKF6y1NToHhe7y1hQwOyzebeDub4IaKFpCjwqcBPHBHMQfPLuSr9d4VrFkDbzF70kH4JHjzvqesABB2GaBNON4EDqnxOEuVhPg4a7pseNaBF+/w/uSh4qChIBAeeoGTuB16zlBmBFPBz7IQBW5obuGHgL+7Y4TRJ5kGeIGXsRM7eLwwhwJRiEvm+tE7t+jFYX+LGInh7HAReprCF0twJO8QYhjgLiKcpZAJEwaa3rmBYNEAA=",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AGwALADMjQAE5u4DGlAhMZ3YvItQezjYZBMAhDviZHgAIhBgFI3U2Jho+AoEGJqBAOL8AuY4GIAYoyC1CG0CB0uhwekECHAYAkIGRzpEQONJsEZtQ5ggFktnOsctTdqcuhcoFdzDc7mhHs83h8yCEAFIxEIAQWwCQcmAgCR2AAoAFbCAB0/EVBzgR3lwjOjKg/GwtwmdW5AEoQN8QL9/vJYSCwbUyJDoZagXDKK0vO00GdUdQAuiCQlwm1qITMsTcKT5ot8M5CGQ1MIhPYTT8/vgPQtreC7VCdIDlKaoPGIGhkgi3UiPSirNiNNgoCGMf6iugYNxqFBahbQD7iXGAkzMq3S94xJ1I9RsQEJvWQAlFhcRZXqF5Oph6gQGtQkGoi/gAKzwjxlkDI7rj6K16dCYhkBCFDs8EDdgi+DWXAdWQ/D+JjmcQSdEghZ21XFsVPEBl0SYINxALcdzwXcAAYD0RY8KzA6tcTrUYGzvBxO0fXpn17N8cEHT92m/QcJzZacgPnUCvXA+JVyglQYN8OD93zSioy7QJHFxC4e1fftSKsORajUdAH3IwDAkxOxZgjVjyiPDQoTSCgmiXLpbgSd4ayw2so1NYRdLIBImHvIiRKM7glCAA=",
        },
      ],
    },
  ],
  "1523": [
    {
      sectionTitle: "iOS",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AKwATADMjQAEAHIAEns7MULuAIIOaCHmmGrCcFDcjdTYmGj4Cghi1BAcbwFzI8dF9lJRWl52mhOt1qAEggQ4DAEhAyNheJEQONJsEZtQ5ggFktnJsAJwABnJu1u90eUBCEAA8kwdgAKHI5ABsABZuZSeZyAJQgF4gN4feSKMi/f61MhAsSfKVNahQYRINDJQhtAgdLocKwojTYKCZYLCGCkaAAC0cIREwhC2DgflVtQloHhwV4SF4AGtuOVIbr4otDRAAhMzQQEosMdgUbCQF5Oph6gQGtQkGoIBLVuSVSAxJ0w3hXCB4Y40RjcfcAk9MoHrLU1OgeNrgyBYwoYHZZvNvJmO94NI80hQWhXAlX0d4fRB/U25C223gId5u0i+/iB8Ehx5O6PYAFM57pwFZ96cGBHKacEuVx7h8FN72PjvCYOVM+CEfx6epzkGcawIfgiisZcHFbJ8Dw3QIt3fcBdwzb9YOCP8T0nSsLxAkBeAeCDH3bNCY3gt9+0/PdUJ1EAMInUVhC6bAyASAARaITUbfBnAYpiWKYd1vV9AMGiAA=",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AKwATADMjQAEAHIAEns7MULuAIIOaCHmmGrCcFDcjdTYmGj4Cghi1BAcbwFzI8dF9lJRWl52mhOt1qAEggQ4DAEhAyNheJEQONJsEZtQ5ggFktnJsAJwABnJu1u90eUBCEAA8kwdgAKHI5ABsABZuZSeZyAJQgF4gN4feSKMi/f61MhAsSfKUtQhtAgdLocHoIrEJcJtajYzK43D4+aLfDOQhke5Cewi17vfDQhYygHy4FK75kJrUKDCJBoZKqyHq6GaqwojTYKDGxF6oroGDcP21CWgeG4+4BJ6ZFMh7xiToW6gogITOMgBKLDHYFGwkBeTqYeoEBrUJBqCAS1YqjyhkAahtRtGx0YEITEMgIQppnggTMEXzCHNjmNWfuF+IlqsQcs4gjVqC1+ta6hNxLBdsgTvd/Crcl9tWD8PD6Ixytweqpq7zxfTbNFjXfNN3abd8zLMgK3HKsazRU8rAvFsrxUG9fDvPBe1FItUWDeFHDRDEsxXIC8ysORajUdB51Aw9AiROxZnNFDygHDRHjSChfUbLpsDIBIABF32Ay1RWEXj+KYOcl0A3McG4JQgA=",
        },
      ],
    },
    {
      sectionTitle: "Android",
      cards: [
        {
          title: "Top 50 Publishers",
          description: "by impressions",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwA6lZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AKwATADMjQAEAHIAEns7MULuAIIOaCHmmGrCcFDcjdTYmGj4Cghi1BAcbwFzI8dF9lJRWl52mhOt1qAEggQ4DAEhAyNheJEQONJsEZtQ5ggFktnJsABwATnJu1u90eUBC52wCR2jISDkwEGZAAp+GoAHTYAAW2AefN4ogAlCAXiA3h95IoyL9/rUyECxJ9FU1qFBhEg0MlCG0CB0uhwrCiNNgoJlgsIYKRoILHCERMIQtg4H4dbV5aB4cFeEheABrbjlSEm+KLC0QAITW0EBKLDFMs1WLydTD1AgNahINQQeWrAAM2pAYk6MbwrhA8McaIxuPuASemXD1lqanQPCNkZAyYUMDss3m3jzfe8GkeaQoLTrgQb6O8QYgoY7ci7PbwEO8g6RI/xY+CE48/ensACef9i4Cy8DODAjhtOA3W79k+C++HHyPhPHKifgQF6zteC5yEuTYEPwRRWJuDjdh+Z57oEB6/uAx65oByHBCBV7zvWd5QSA4pwHB769jhSaoT+o7/ie2HGiAeFzjKwhdNgZAJAAItE1rtvgzhsRxXFML6gbBmGDRAA===",
        },
        {
          title: "Active Creatives Preview",
          description: "",
          link: "https://reports.remerge.io/#detailed_reports/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhpwBGCApiADTjTxKoY4DKZaG2A5lPqAMaYIEcAA5QyAJUwB3bngBmiMQF9qGALZlkOCgQCiaXgHoAqgBUAwlRByICNGQBOsgNqg0AT2E7CEDVYdkcvggAPoh6hSqvmQACo5YACYuoAkwDuhYuAQxAIwAslZQ9sL4Oaqe3nIImJgBCSBKALoqbhXBRQ4QnP6BwbUccNgoGThWcLyMWSBdVmCIMGQuIM45AKwATADMjQAEAHIAEns7MULuAIIOaCHmmGrCcFDcjdTYmGj4Cghi1BAcbwFzI8dF9lJRWl52mhOt1qAEggQ4DAEhAyNheJEQONJsEZtQ5ggFktnJsABwATnJu1u90eUBC52wCR2jISDkwEGZAAp+GoAHTYAAW2AefN4ogAlCAXiA3h95IoyL9/rUyECxJ9FS1CG0CB0uhwegisQlwm1qNjMrjcPj5ot8M5CGR7kJ7NLXu98NCFsqAWrgZrvmQmtQoMIkGhkjrIXroQarCiNNgoFbEaaiugYNxQ7V5aB4bj7gEnpls9HvGJOvbqCiAhNUyAEosMUz49QvJ1MPUCA1qEg1BB5attR4YyB9bDG9Fkw2hMQyAhCrmeCACwRfMJiymcGXRxX4tWp3WcQQm1AWyjJx3EsFeyB+4P8KsAAwj3XjuOTxNo7dTOD1HMrhXNdpiLRZf13d9K1RMtazIetRlPZs0UvQ123iLtbxUe9fEfPBhxlaD7TwVxV0CRw0QxQtN3A0srDkWo1HQFc92CJsFBgOxZjtLDyjHDRHjSCgQxAYQumwMgEgAEWnCCHRlMTsAkhImGXdcwJLHcGiAA",
        },
      ],
    },
  ],
};

type ReportLinksProps = {
  organizationId: string;
};

export default function ReportLinks(props: ReportLinksProps) {
  return (
    <div className={classes.container}>
      {reportLinks[props.organizationId] && props.organizationId !== SELF_ORG_ID && (
        <LinkCards organizationId={props.organizationId} />
      )}
      <h3 className={classes.sectionTitle}>Custom Reports</h3>
      {reportLinks[SELF_ORG_ID] && props.organizationId === SELF_ORG_ID && (
        <div>
          <LinkCards style={{ marginBottom: "20px" }} organizationId={"1553"} />
        </div>
      )}
      <LinkCard
        card={{
          title: "Build your own",
          description: "To dive deep into details, create your own reports",
          link: import.meta.env.VITE_REPORTS_URL,
        }}
        cardClassName={classes.customReportsLink}
        organizationId={props.organizationId}
      />
    </div>
  );
}

function LinkCards(props: { organizationId: string; style?: React.CSSProperties }) {
  return (
    <div style={props.style}>
      {reportLinks[props.organizationId].map(reportSection => (
        <section key={reportSection.sectionTitle}>
          {props.organizationId !== SELF_ORG_ID && (
            <h3 className={classes.sectionTitle}>{reportSection.sectionTitle}</h3>
          )}
          <div className={classes.cardRow}>
            {reportSection.cards.map(card => (
              <LinkCard key={card.title} card={card} organizationId={props.organizationId} />
            ))}
          </div>
        </section>
      ))}
    </div>
  );
}

function LinkCard(props: { card: Card; organizationId: string; cardClassName?: string }) {
  const trackingService = useContext(TrackingContext);
  const onClick = () => {
    trackingService.track(
      new ClickReportsLinkEvent({
        "Organization Id": props.organizationId,
        "Report Name": props.card.title,
      }),
    );
  };

  return (
    <a href={props.card.link} className={classNames(classes.card, props.cardClassName)} onClick={onClick}>
      <h5 className={classes.cardTitle}>{props.card.title}</h5>
      <h6 className={classes.cardDescription}>{props.card.description}</h6>
    </a>
  );
}
