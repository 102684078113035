import { Icon } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import { i18n } from "shared/utilities/I18nContext";
import { CurrencyCode } from "shared/utilities/isoCodes";
import { calculateAdTypeDataTableColumns } from "../common/calculateAdTypeDataTableColumns";
import formatNumber from "../common/formatNumber";
import { DataTableTimeSeries } from "../common/transformData";
import classes from "./Dashboard.module.css";
import DashboardDataTable from "./DashboardDataTable";
import LoadingBars from "./LoadingBars";
import { NoDataBanner } from "./NoDataBanner";
import { StackedAreaChart } from "./StackedAreaChart";
import Legend from "./charts/Legend";
import { Series } from "./charts/types";

type CreativeReportProps = {
  isMobile: boolean;
  selectedClient: string;
  adTypeApConversionsSeries?: Series[];
  adTypeSpendSeries?: Series[];
  adTypeAPConversionsData: DataTableTimeSeries[];
  adTypeBuyingPriceData: DataTableTimeSeries[];
  currencyCode: CurrencyCode;
};

const currencyCodeToLabel: { [code in "USD" | "JPY"]: string } = {
  USD: "Dollars",
  JPY: "Yen",
};

export function CreativeReport({
  isMobile,
  selectedClient,
  adTypeApConversionsSeries,
  adTypeSpendSeries,
  adTypeAPConversionsData,
  adTypeBuyingPriceData,
  currencyCode,
}: CreativeReportProps) {
  const [creativeApConversionsnDataTableVisible, setCreativeApConversionsnDataTableVisible] =
    useState<boolean>(!isMobile);
  const [creativeSpendDataTableVisible, setCreativeSpendDataTableVisible] = useState<boolean>(!isMobile);
  const adTypeDataEntry = adTypeAPConversionsData ? adTypeAPConversionsData[0] : undefined;

  const buyingPriceTableColumns = adTypeDataEntry
    ? calculateAdTypeDataTableColumns(adTypeDataEntry, "buying_price")
    : [];
  const apConversionsTableColumns = adTypeDataEntry
    ? calculateAdTypeDataTableColumns(adTypeDataEntry, "ap_conversions")
    : [];

  const currencyLabel: string | undefined = currencyCodeToLabel[currencyCode as "USD" | "JPY"];

  return (
    <>
      <div className={classNames(classes.creativePerformance, classes.spacingTop)}></div>
      <div className={classNames(classes.creativePerformance, classes.sectionTitle, classes.sectionPadding)}>
        <h4>Creative Type Report</h4>
      </div>

      <div className={classNames(classes.creativeSectionContainer, classes.sectionPadding)}>
        <div
          className={classNames(classes.creativePerformance, classes.creativeSectionColumn, {
            [classes.chartSectionLoading]: !adTypeApConversionsSeries,
          })}
        >
          <div className={classNames(classes.creativePerformance, classes.spacingBottom)}>
            <h5>AP Conversions</h5>
          </div>

          {adTypeApConversionsSeries && adTypeApConversionsSeries.length === 0 ? (
            <NoDataBanner />
          ) : (
            <>
              {adTypeApConversionsSeries ? (
                <>
                  <StackedAreaChart
                    series={adTypeApConversionsSeries as Series[]}
                    graphName="Creative Type Report - AP Conversions"
                    organizationId={selectedClient}
                    formatter={v => formatNumber(v, true)}
                  />
                  <div className={classNames(classes.legend, classes.legendMargin)}>
                    <Legend series={adTypeApConversionsSeries as Series[]} orientation="horizontal" />
                  </div>

                  <div
                    className={classes.summary}
                    onClick={() => setCreativeApConversionsnDataTableVisible(!creativeApConversionsnDataTableVisible)}
                  >
                    <div>Summary</div>
                    <div>
                      <Icon>
                        {creativeApConversionsnDataTableVisible ? "keyboard_arrow_up" : "keyboard_arrow_down"}
                      </Icon>
                    </div>
                  </div>

                  <div
                    className={classNames(
                      classes.horizontalScroll,
                      creativeApConversionsnDataTableVisible ? classes.visible : classes.hidden,
                    )}
                  >
                    <DashboardDataTable data={adTypeAPConversionsData} columns={apConversionsTableColumns} />
                  </div>
                </>
              ) : (
                <LoadingBars />
              )}
            </>
          )}
        </div>
        <div
          className={classNames(classes.creativePerformance, classes.creativeSectionColumn, {
            [classes.chartSectionLoading]: !adTypeSpendSeries,
          })}
        >
          <div className={classNames(classes.creativePerformance, classes.spacingBottom)}>
            <h5>Spend {currencyLabel ? `in ${currencyLabel}` : ""}</h5>
          </div>

          {adTypeApConversionsSeries && adTypeApConversionsSeries.length === 0 ? (
            <NoDataBanner />
          ) : (
            <>
              {adTypeSpendSeries ? (
                <>
                  <StackedAreaChart
                    series={adTypeSpendSeries as Series[]}
                    formatter={value => i18n.formatMoney({ value, currencyCode }, "short")}
                    graphName="Creative Type Report - Spend in Dollars"
                    organizationId={selectedClient}
                    currencyCode={currencyCode}
                  />
                  <div className={classNames(classes.legend, classes.legendMargin)}>
                    <Legend series={adTypeSpendSeries as Series[]} orientation="horizontal" />
                  </div>

                  <div
                    className={classes.summary}
                    onClick={() => setCreativeSpendDataTableVisible(!creativeSpendDataTableVisible)}
                  >
                    <div>Summary</div>
                    <div>
                      <Icon>{creativeSpendDataTableVisible ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
                    </div>
                  </div>

                  <div
                    className={classNames(
                      classes.horizontalScroll,
                      creativeSpendDataTableVisible ? classes.visible : classes.hidden,
                    )}
                  >
                    <DashboardDataTable data={adTypeBuyingPriceData} columns={buyingPriceTableColumns} />
                  </div>
                </>
              ) : (
                <LoadingBars />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
